<template>
  <div>
    <a-form class="form-box" :model="formState">
		<a-form-item>
		  <img src="../static/title.jpg" alt="">
		</a-form-item>
		<a-form-item>
		  <div class="tit">|&nbsp;账号密码登录</div>
		</a-form-item>
      <a-form-item>
        <a-input v-model:value="formState.account" placeholder="请输入账号" class="input-box">
			<template #prefix>
			    <user-outlined type="user" />
			</template>
		</a-input>
      </a-form-item>
      <a-form-item>
        <a-input type="password" v-model:value="formState.password" placeholder="请输入密码" class="input-box">
			<template #prefix>
				<lock-outlined />
			</template>
		</a-input>
      </a-form-item>
      <a-form-item>
        <button @click="onSubmit" class="btn">登 录</button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
export default {
  name:'LoginModule',
  data(){
    return {
      formState:{
        account:'',
        password:'',
      }
    }
  },
  components:{
  },
  methods:{
    // async submit(obj){
    //   var ldm = $utils.loading('正在提交...');
    //   var res = await api.sendAJAX({
    //     method:'post',
    //     ...obj
    //   })
    //   ldm.
    //   $utils.loadEnd(ldm,'提交成功！')
    //   return res
    // },
    // onSubmit(){
    async onSubmit(){
      var that = this,
          ldm = that.$utils.loading('正在登录...');
      // var user = 0;
      var user = await this.$utils.api.post({
        // url:'/token/check',
        url:'/user/login',
        data:{
          ...that.formState
        },
        // callback(res){
        // }
      });
      if(user){
        that.$utils.loadEnd(ldm,'登录成功！')
        that.$store.commit('setStore', {
          name:'user', 
          data:user.userinfo,
          storage:true
        })
        that.$router.replace({name:'Home'})
      }else{
        that.$store.commit('setStore', {
          name:'user', 
          data:null,
          storage:true
        })
        ldm.stop();
      }

        // console.log('login page',list)

      // this.$tools.post({
      //   url:'/projectType/projectInfoList'
      // });
      // console.log(this.$tools.post)
    }
  }
};
</script>

<style lang="scss" scoped>
	.form-box{
		width: 450px;
		height: 480px;
		background-color: #fff;
		border-radius: 10px;
		overflow: hidden;
		img{
			margin-top: 30px;
			margin-left: 10px;
		}
		.tit{
			font-size: 18px;
			font-weight: bold;
			margin-left: 40px;
			margin-top: 20px;
			margin-bottom: 20px;
			color: #666;
		}
		.input-box{
			width: 375px;
			height: 50px;
			margin-left: 40px;
			
		}
		.btn{
			width: 375px;
			height: 50px;
			margin-left: 40px;
			margin-top: 15px;
			background: linear-gradient(to right,rgba(0,64,151,1),rgba(32,93,217,1));
			border: 0px;
			border-radius: 3px;
			color: #fff;
			font-size: 14px;
		}
	}
</style>