<template>
	<!-- 签约 -->
	<div class="modal-box">
		<a-form 
			class="third-box" 
			:model="info"
			:label-col="{ span: 8}"
			:wrapper-col="{ span: 12 }"
			@finish="onFinish">
			<div class="first one">
				<p>签约</p>
				<close-outlined @click="close()"/>
			</div>
			<a-form-item
				label="项目名称" 
				:colon= "false" 
				:rules="[{ required: false}]">
			    {{signInfo.signingName}}
			</a-form-item>
			<a-form-item
				label="合同编号" 
				name="contractNo" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入合同编号' }]">
			    <a-input v-model:value="info.contractNo" placeholder="请输入合同编号" />
			</a-form-item>
			<a-form-item
				label="合同金额" 
				name="contractMoney" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入合同金额' }]">
			    <a-input type="Number" v-model:value="info.contractMoney" placeholder="请输入合同金额" />
			</a-form-item>
			<a-form-item  :wrapper-col="{ offset:8}" style="margin-top: 50px;">
			  <a-button class="clo1" style="margin-right: 50px;" html-type="submit">提交</a-button>
			  <a-button class="clo6" @click="close()">取消</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				info:{
					contractNo:'' ,// 合同编号
					contractMoney:null// 合同金额
				}
			}
		},
		props:{
			signInfo:{
				type:Object
			}
		},
		methods:{
			close(){
				this.$store.commit('setIsSigning',0)
			},
			async onFinish(e){
				// 提交签约
				let res = await this.$utils.api.post({
					url:'/sale/signing',
					data:{
						ids:this.signInfo.signingId,
						contract_no: e.contractNo,
						contract_money: e.contractMoney
					},
					result:1
				})
				if(res.code == 1){
					this.$store.commit('setSignId',this.signInfo.signingId)
					this.$utils.msgSuc('签约成功')
					this.close() // 关闭
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 500px;
			height: 350px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				margin-bottom: 20px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
		}
	}
</style>