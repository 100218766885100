<template>
  <div>
    <a-dropdown v-if="outTabs">
      <template #overlay>
        <a-menu>
          <a-menu-item v-for="item in projectList" @click="setProId(item.id,item.name)">
            <span v-text="item.name"></span>
          </a-menu-item>
        </a-menu>
      </template>
      <div class="ant-dropdown-link" @click.prevent>
        <span v-text="onProject.name||'无项目'"></span>
      </div>
    </a-dropdown>
    <div v-else style="width:280px;"></div>
    <div class="g-tab-nav" v-if="tabs&&tabs.length">
      <div class="item" v-for="(item,idx) in tabs" :class="idx==onIdx?'on':''" @click="tabsCheck(idx)">
    		<div class="tab-child">
    			{{item.title}}
    			<!-- 设置开发-指派我的bug 未解决数 \ 我提出的bug待确认数量 -->
    			<p 
    				class="redNum" 
    				v-text="unresolvedForDev" 
    				v-if="item.title == 'BUG' && mainLable == 'develop' && unresolvedForDev > 0"></p>
    			<p 
    				class="redNum" 
    				v-text="waitNotForDev" 
    				v-if="item.title == 'BUG' && mainLable != 'develop' && waitNotForDev > 0"></p>
    			<!-- 设置任务逾期输 -->
    			<p 
    				class="redNum" 
    				v-text="overdueNum" 
    				v-if="item.title == '任务' && overdueNum > 0"></p>
    		</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  data(){
    return {
      // onIndex: 0,
      // navList:[],
      projectList:[],
    }
  },
  props:['onIdx','tabs','navname'],
  computed: {
    outTabs(){
      var names = ['销售机会','客户管理','收款记录'];
      return names.findIndex(t=>this.tabs&&t==this.tabs[this.onIdx].title) < 0
    },
    user() {
      return this.$store.state.user
    },
    proId() {
      return this.$store.state.proId
    },
    onProject(){
        var pro = this.projectList.find(item=>item.id==this.proId)
		if(pro){
			this.$store.commit('setStoreName',pro.name)
		}
			return pro || {}
		},
	mainLable(){
		return this.$store.state.mainLable
	},
	unresolvedForDev(){
		// 开发-指派我的bug未解决数量
		return this.$store.state.unresolvedForDev
	},
	waitNotForDev(){
		// 产品、项目、测试 待确认bug数量
		return this.$store.state.waitNotForDev
	},
	overdueNum(){
		//项目经理、开发任务逾期数量
		return this.$store.state.overdueNum
	}
  },
  watch:{
	  navname(val,old){
		  if(val){
			  // this.$store.commit('setStore', {
			  //   name:'proId', 
			  //   data:null,
			  //   storage:true
			  // })
			  this.getProject()
		  }
	  }
  },
  created(){
	  console.log("sss",this.mainLable)
    this.getProject()
  },
  methods:{
    async getProject(){
      var project = await this.$utils.api.get({
        url:'/project/my_selectpage',
		data:{
			group:this.navname
		}
      })

      if(project.list&&project.list.length){
        if(!this.$store.state.proId){
          this.$store.commit('setStore', {
            name:'proId', 
            data:project.list[0].id,
            storage:true
          })
        }else if(!project.list.find(item=>item.id==this.$store.state.proId)){
          this.$store.commit('setStore', {
            name:'proId', 
            data:project.list[0].id,
            storage:true
          })
        }
      }
      // var jud =
      // if((!this.$store.state.proId)&&project.list.length){
      //   this.$store.commit('setStore', {
      //     name:'proId', 
      //     data:project.list[0].id
      //   })
      // }
      this.projectList = project.list;
      // console.log('project ',project);
      // /project/my_selectpage
    },
    setProId(id,name){
      this.$store.commit('setStore', {
        name: 'proId', 
        data: id,
        storage:true
      })
		this.$store.commit('setStoreName',name)
    },
    tabsCheck(idx){
      if(this.onIdx==idx){
        this.$emit("update:onIdx", -1);
        setTimeout(()=>{
          this.$emit("update:onIdx", idx);
        })
      }else{
        this.$emit("update:onIdx", idx);
      }
      // console.log('页面参数名', this.tabs[idx].name,this.$route.path,this.$route.name)
      // if(this.tabs[idx].name==this.$route.name){
      //   // this.$router.go(0);
      //   console.log('页面刷新');
      // }else{
      //   this.$router.replace({name:this.tabs[idx].name, params:{data:JSON.stringify(this.tabs[idx].child)}})
      // }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ant-dropdown-link{
  width:280px;
  height: 70px;
  font-size:18px;
  font-weight: bold;
  color:#014098;
  padding:15px 30px 15px 10px;
  line-height:40px;
  height: 70px;
  display: flex;
  align-items: center;
  border-right: 1px solid #f0f3fa;
  span{
    flex:1;
  }
  &:after{
    content:'';
    display: block;
    width:12px;
    height:12px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
  }
}
.g-tab-nav{
  flex:1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  .item{
    padding:10px 10px 0;
    font-size:14px;
    line-height:50px;
    position: relative;
    min-width:100px;
    margin:0 10px;
    text-align: center;
    &.on{
      color:#014098;
      &:after{
        content:'';
        display: block;
        position: absolute;
        left:0;
        right:0;
        bottom:0;
        border-top: 2px solid;
      }
    }
  }
}
.tab-child{
	position: relative;
	.redNum{
		position: absolute;
		top: 5px;
		right: 0px;
		z-index: 10;
		min-width: 20px;
		height: 20px;
		background-color: red;
		text-align: center;
		line-height: 20px;
		color: #fff;
		border-radius: 50%;
	}
}
</style>
