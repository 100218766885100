<template>
	<!-- 添加团队成员 -->
	<div class="modal-box">
		<div class="third-box">
			<div class="first one">
				<p>创建项目</p>
				<close-outlined @click="close()"/>
			</div>
			<div class="first1">
				<p>项目名称</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick">
							<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem.name}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button v-if="stateArr.length > 0">
						{{stateArr[stateIndex].name}}
				        <DownOutlined />
				    </a-button>
					<p style="color: #999999;" v-else>暂无待创建项目</p>
				</a-dropdown>
			</div>
			<div class="btn">
				<a-button class="clo1" @click="submit()">确定</a-button>
				<a-button class="clo6" @click="close()">取消</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				stateArr:[] ,
				stateIndex:0,
			}
		},
		created() {
			this.getProjectList()
		},
		methods:{
			close(){
				this.$store.commit('setIsManagerAdd',0)
			},
			handleMenuClick(e){
				this.stateIndex = e.key
			},
			async getProjectList(){
				// 获取项目列表
				let res = await this.$utils.api.get({
					url:'/project/wait_selectpage',
					result:1
				})
				if(res.code == 1){
					this.stateArr = res.data.list
				}
			},
			async submit(){
				if(this.stateArr.length > 0){
					let id = this.stateArr[this.stateIndex].id
					let res = await this.$utils.api.post({
						url:'/project/wait',
						data:{
							ids:id
						},
						result:1
					})
					if(res.code == 1){
						this.$utils.msgSuc('创建成功')
						this.$store.commit('setIsFresh',1) // 刷新列表
						this.close() // 关闭
					}
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 500px;
			height: 250px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.first1{
				display: flex;
				padding: 30px 20px 0 20px;
				p{
					padding: 0;
					width: 100px;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
			.btn{
				display: flex;
				justify-content: center;
				margin-top: 40px;
				.clo1{
					margin-right: 30px;
				}
			}
		}
	}
</style>