<template>
  <div class="g-title">
    <h3 class="name" :class="type?'':'fx'">{{name}}　<span class="tip" v-if="tip" v-text="tip"></span></h3>
    <div class="more" :class="type?'fx':''"><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'GTitle',
  data(){
    return {
    }
  },
  props:{
    name:'',
    tip:'',
    type:0
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.g-title{
  display: flex;
  padding-bottom:10px;
  .fx{
    flex:1;
  }
  .name{
    font-size:16px;
    line-height:30px;
    margin-bottom:0;
    .tip{
      font-size:12px;
      opacity: .7;
    }
    &:before{
      content:'';
      display: inline-block;
      vertical-align: middle;
      border: 1px solid;
      width:2px;
      height:16px;
      position: relative;
      top:-2px;
      opacity: .6;
      margin-right:10px;
    }
  }
}
</style>
