<template>
	<!-- 客户管理-编辑-->
	<div class="modal-box">
		<a-form 
			class="third-box" 
			:model="info"
			:label-col="{ span: 8}"
			:wrapper-col="{ span: 12 }">
			<div class="first one">
				<p>编辑</p>
				<close-outlined @click="close()"/>
			</div>
			<a-form-item
				label="项目名称" 
				name="name" 
				:colon= "false" 
				:rules="[{ required: false }]">
			   {{info.name}}
			</a-form-item>
			<a-form-item
				label="客户名称" 
				name="nickname" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入客户名称' }]">
			    <a-input v-model:value="info.nickname" placeholder="请输入客户名称" />
			</a-form-item>
			<a-form-item
				label="客户标签" 
				name="label_ids" 
				:colon= "false" 
				:rules="[{ required: true, message: '请选择客户标签' }]">
			    <a-select
			       mode="tags"
			       style="width: 100%"
				   searchValue= ""
			       placeholder="请选择标签"
				   :value="lableArr"
			       :options="stateArr"
			       @change="handleChange"
			     ></a-select>
			</a-form-item>
			<a-form-item
				label="联系人" 
				name="linkman" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入联系人' }]">
			    <a-input v-model:value="info.linkman" placeholder="请输入联系人" />
			</a-form-item>
			<a-form-item
				label="联系方式" 
				name="contact" 
				:colon= "false"
				:rules="[{ required: true, message: '请输入联系方式' }]">
			    <a-input v-model:value="info.contact" placeholder="请输入联系方式" />
			</a-form-item>
			<a-form-item
				label="备注" 
				name="remark" 
				:colon= "false" 
				:rules="[{ required: false, message: '请输入备注' }]">
			    <a-input v-model:value="info.remark" placeholder="请输入备注" />
			</a-form-item>
			<a-form-item  :wrapper-col="{ offset:8}" style="margin-top: 50px;">
			  <a-button class="clo1" style="margin-right: 50px;" @click="updateBtn()">提交</a-button>
			  <a-button class="clo6" @click="close()">取消</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				stateArr:[], // 标签
				stateIndex:0, // 标签下标
				info:{
					name:'', // 项目名称
					nickname:'' ,//客户名称
					label_ids:[], // 客户标签
					linkman:'' , // 联系人
					contact:'' , // 联系方式 
					remark:'' , // 备注
				},
				lableArr:[] // 标签名称
			}
		},
		computed:{
			updateId(){
				return this.$store.state.updateId
			}
		},
		async created() {
			// 获取客户标签
			let res = await this.$utils.api.get({
				url:'/sale/label_selectpage'
			})
			for(let i in res.list){
				res.list[i].value = res.list[i].name
			}
			this.stateArr = res.list
			// 加载编辑信息
			if(this.updateId){
				this.getInfo(this.updateId)
			}
		},
		methods:{
			close(){
				// 关闭弹窗
				this.$store.commit('setUpdateId',null) // 重置id
				this.$store.commit('setIsCollectUpdate',0)
			},
			handleChange(value,item){
				// 多选标签
				let ids = []
				let arr = []
				for(let i in item){
					ids.push(item[i].id)
					arr.push(item[i].name)
				}
				this.info.label_ids = ids
				this.lableArr = arr
			},
			async getInfo(id){
				// 编辑详情
				let res = await this.$utils.api.post({
					url:'/contract/getRow',
					data:{
						ids:id
					},
					result:1
				})
				if(res.code == 1){
					let arr = []
					for(let i in res.data.sale.labels_arr){
						arr.push(res.data.sale.labels_arr[i].id)
						this.lableArr.push(res.data.sale.labels_arr[i].name)
					}
					this.info = {
						name:res.data.project_name, // 项目名称
						nickname:res.data.sale.nickname,//客户名称
						label_ids:arr, // 客户标签
						linkman:res.data.sale.linkman, // 联系人
						contact:res.data.sale.contact, // 联系方式 
						remark:res.data.sale.remark // 备注
					}
				}
			},
			async updateBtn(){
				if(this.info.nickname == ''){
					this.$message.error('客户名称不能为空');
					return
				}
				if(this.info.label_ids.length == 0){
					this.$message.error('客户标签不能为空');
					return
				}
				if(this.info.linkman == ''){
					this.$message.error('联系人不能为空');
					return
				}
				if(this.info.contact == ''){
					this.$message.error('联系方式不能为空');
					return
				}
				// 修改
				let row = {
					'ids':this.updateId,
					'row':{
						'nickname':this.info.nickname,
						'linkman':this.info.linkman,
						'contact':this.info.contact,
						'remark':this.info.remark,
						'label_ids':this.info.label_ids
					}
				}
				let res = await this.$utils.api.post({
					url:'/contract/edit',
					data:row,
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('修改成功')
					this.$store.commit('setIsCollectUpdateFresh',this.updateId) // 刷新列表
					this.close() // 关闭
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 550px;
			height:620px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				margin-bottom: 50px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
		}
	}
</style>