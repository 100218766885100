import $axios from '@/request';
import qs from 'qs';
import $store from '@/store';
import $router from '@/router';
import $utils from '@/components/utils/tools';
import config from '@/components/utils/config';

const data = {
  path:'/api'
};

const api = {
	 getUrlOption(){
		 // 获取路由参数
		let str = location.search.substr(1).split("&");
		let obj = {};
		str.forEach(e => {
			let list = e.split("=");
			obj[list[0]] = list[1];
		});
		return obj
	},
  sendAJAX(obj) {
    // console.log("路由参数",api.getUrlOption())
    var param = {
       method:obj.method||'get',
       url:data.path + obj.url,
       data:obj.data||null
    };
    if(obj.responseType)param.responseType = obj.responseType;
    if($store.state.user){
      param.headers = {token:$store.state.user.token}
    }
    if($store.state.proId&&$store.state.user){
      param.headers.proid = $store.state.proId;
      // param.headers = {proid:$store.state.user.proId}
    }
	
	let query =  api.getUrlOption()
	if($store.state.user){
		// param.headers.label = $store.state.mainLable;
		if(obj.isRule){
			// app.vue页面刷新 首次加载时
			param.headers.label = ""
		}else{
			if($store.state.mainLable){
				param.headers.label = $store.state.mainLable
			}else{
				if(query&&query.label){
					param.headers.label = query.label
				}
			}
		}

		// console.log("地址栏label",query.label,"点击菜单label",$store.state.mainLable,"结果",param.headers.label)
	}
	
    if(param.method=='get'&&param.data){
       param.url += '?' + qs.stringify(param.data)
    }
    return new Promise((resolve, reject) => {
      $axios(param).then(response => {
            console.log('Api Response', response.data)
            if(response.code == 0){
                $utils.msgErr(response.msg)
    			resolve(response.data)
            }else if(obj.result){
    			resolve(response)
    		}else{
    			resolve(response.data)
    			// console.log('请求完成')
    		}
      },
      error => {
        console.log(error)
        if(error.response.status == 401){// 'Request failed with status code 401'
          $utils.msgErr('登录失效，请重新登录！');
          $router.replace({name:'Login'})
          $store.commit('setStore', {
            name:'user', 
            data:null,
            storage:true
          })
        } else {
          $utils.msgErr('网络错误'+error)
        }
        reject(error)
      })
      .catch(err=>{
        console.error(err)
      })
    })
  },
  async post(obj){
    var res = await api.sendAJAX({
      method:'post',
      ...obj
    })
    return res
  },
  async get(obj){
    var res = await api.sendAJAX({
      ...obj
    })
    return res
  },
  // downLoad(obj){
  //     console.log(config.baseURL)
  //     const link = document.createElement('a')
  //     link.href = config.baseURL + data.path + obj.url;
  //     // link.download = '.xlsx'// 自定义文件名
  //     //这里是获取后台传过来的文件名
  //     link.setAttribute('download',obj.name||(new Date()+''))
  //     link.click()
  // },

  async downLoad(obj){
    // if(obj.data){
    //    obj.url += '?' + qs.stringify(obj.data)
    // }

    var res = await api.sendAJAX({
      method:'post',
      ...obj,
      result:1,
      responseType:'arraybuffer'
    })
    if (!res || res.code == 0) {
        this.$message.error("下载模板文件失败");
        return false;
    }
    const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
    const downloadElement = document.createElement('a');
    const href = window.URL.createObjectURL(blob);
    // let contentDisposition = res.headers['content-disposition'];  //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
    // let patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
    // let result = patt.exec(contentDisposition);
    // let filename = decodeURI(result[1]);
    downloadElement.style.display = 'none';
    downloadElement.href = href;
    downloadElement.download = obj.name; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象

    // $axios.post(data.path + obj.url,{
    //     responseType:'blob', //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置
    //     headers:{
    //       token:$store.state.user.token,
    //       proId:$store.state.proId,
    //     },
    //     data:obj.data,
    //   }).then(res => {
    // })
    // .catch(function (error) { // 请求失败处理
    //   console.log(error);
    // });
  },

  async upLoad (e,type) {
    // 执行上传
    let that = this
    const formData = new FormData()
	console.log(":")
	if(type === 'document'){
		// input 不同类型文件
		formData.append('file', e)
	}else{
		// a-input图片
		formData.append('file', e.file)
	}
    formData.append('token', $store.state.user.token) // 登录token
    var res = await api.sendAJAX({
      method:'post',
      url:'/common/upload',
      data:formData,
      result:1
    })
    return res

    // 上传图片
    // let res = await this.$utils.api.post({
    //   url:'/common/upload',
    //   data:formData,
    //   result:1
    // })
    // if(res.code == 1){
    //   setTimeout(()=>{
    //     $utils.msgSuc(response.msg)
    //     that.$utils.msgSuc('上传成功')
    //     that.fileList[0].status = 'done' // 上传成功修改文件状态
    //     that.fileList[0].thumbUrl = res.data.fullurl // 图片封面预览
    //     that.info.images = res.data.fullurl // 收款凭证
    //   },500)
    // }
  },
	copyText (data) {
	  return new Promise((resolve, reject) => {
	    const url = data
	    const oInput = document.createElement('input')
	    oInput.value = url
	    document.body.appendChild(oInput)
	    oInput.select() // 选择对象;
	    document.execCommand('Copy') // 执行浏览器复制命令
	    oInput.remove()
	    resolve(`已复制：${data}`)
	  })
	}
}

export default {
  post:api.post,
  get:api.get,
  upLoad:api.upLoad,
  downLoad:api.downLoad,
  baseURL:config.baseURL,
	copyText:api.copyText
}