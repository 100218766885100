<template>
	<!-- 添加团队成员 -->
	<div class="modal-box">
		<div class="third-box">
			<div class="first one">
				<p>添加团队成员</p>
				<close-outlined @click="close()"/>
			</div>
			<div class="first1">
				<p>角色</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick">
							<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem.name}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button v-if="stateArr.length > 0">
						{{stateArr[stateIndex].name}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="first1">
				<p>姓名</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleNameClick">
							<a-menu-item v-for="(item,index) in nameArr" :key="index">{{item.nickname}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button v-if="nameArr.length > 0">
						{{nameArr[nameIndex].nickname}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="btn">
				<a-button class="clo1" @click="submit()">确定</a-button>
				<a-button class="clo6" @click="close()">取消</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				stateArr:[], // 角色列表
				stateIndex:0, // 角色下标
				nameArr:[] ,// 姓名列表
				nameIndex:0 // 姓名下标
			}
		},
		computed:{
			proId(){
				return this.$store.state.proId  
			}
		},
		async created() {
			// 获取角色列表
			let res = await this.$utils.api.get({
				url:'/team/group_selectpage',
				result:1
			})
			if(res.code == 1){
				this.stateArr =  res.data.list
			}
			
			this.getUser() // 初始化姓名列表
		},
		methods:{
			close(){
				this.$store.commit('setIsMemberAdd',0)
			},
			handleMenuClick(e){
				this.stateIndex = e.key
				this.getUser() 
			},
			handleNameClick(e){
				this.nameIndex = e.key
			},
			async getUser(){
				// 获取姓名列表
				let res1 = await this.$utils.api.post({
					url:'/team/user_selectpage',
					data:{
						group_id:this.stateArr[this.stateIndex].id,
						project_id:this.proId
					},
					result:1
				})
				if(res1.code == 1){
					this.nameArr =  res1.data.list
				}
			},
			async submit(){
				// 提交
				if(this.stateArr.length == 0){
					this.$utils.msgWar('请选择角色')
					return
				}
				if(this.nameArr.length == 0){
					this.$utils.msgWar('请选择姓名')
					return
				}
				let row = {
					'row':{
						'project_id':this.proId,
						'group_id':this.stateArr[this.stateIndex].id,
						'user_id':this.nameArr[this.nameIndex].id
					}
				}
				let res = await this.$utils.api.post({
					url:'/team/add',
					data:row,
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('添加成功')
					this.$store.commit('setIsFresh',1) // 刷新列表
					this.close() // 关闭
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 500px;
			height: 320px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.first1{
				display: flex;
				padding: 30px 20px 0 20px;
				p{
					padding: 0;
					width: 100px;
					text-align: center;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
			.btn{
				display: flex;
				justify-content: center;
				margin-top: 40px;
				.clo1{
					margin-right: 30px;
				}
			}
		}
	}
</style>