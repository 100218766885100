<template>
	<!-- 产品需求-原始需求 -->
  <div>
    <h1 class="logo"><span>黑盒大数据</span></h1>
    <div class="navigation">
      <div class="item" v-for="(item,idx) in navList" :class="idx==onIndex?'on':''">
        <span class="lab" v-text="item.role" @click="onTab(idx)"></span>
      </div>
      <div class="item" :class="navList.length==onIndex?'on':''">
        <router-link to="/Document?label=document" class="lab" @click="onTab(navList.length,'document')" replace>文档</router-link>
      </div>
			<div class="item" :class="navList.length+1==onIndex?'on':''">
					<router-link to="/SelfCheck?label=selfs" class="lab" @click="onTab(navList.length+1,'selfs')" replace>知识库</router-link>
			</div>
      <!-- <router-link to="/about">About</router-link> -->
    </div>
    <a-dropdown class="user">
      <template #overlay>
        <a-menu>
          <a-menu-item @click="updatePwd()">
            <span>修改密码</span>
          </a-menu-item>
					<a-menu-item @click="loginOut">
					  <span>退出登录</span>
					</a-menu-item>
        </a-menu>
      </template>
      <div class="ant-dropdown-link info" @click.prevent>
        <img :src="user.avatar||''" alt="" class="avatar" />
        <div class="name" v-text="user.nickname"></div>
      </div>
    </a-dropdown>
	<a-modal v-if="isUpdate" v-model:visible="isUpdate" title="修改密码" @ok="updateOk()" @cancel="updateCancel()">
		<div class="pwd">
			<p>原密码</p>
			<a-input type="password" v-model:value="oldPwd" placeholder="请输入原密码"></a-input>
		</div>
		<div class="pwd">
			<p>新密码</p>
			<a-input type="password" v-model:value="newPwd" placeholder="请输入新密码"></a-input>
		</div>
		<div class="pwd">
			<p>确认新密码</p>
			<a-input type="password" v-model:value="newPwdAgain" placeholder="请输入确认新密码"></a-input>
		</div>
	</a-modal>
    <!-- <div class="user">
      <div class="info">
        <div class="name">用户名</div>
        <div class="user-no"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data(){
    return {
		oldPwd:'',
		newPwd:'',
		newPwdAgain:'',
		isUpdate:false
    }
  },
  props:['onIndex','navList','onIdx'],
  computed: {
    user() {
      return this.$store.state.user
    },
	authority(){
		var data = {};
		this.$store.state.params.forEach(item=>{
		  data[item.name.split('/')[2]] = true;
		})
		return data
	},
  },
  created(){
    // this.getUser()
	this.getNum()
  },
  methods:{
		async getNum(){
			// 获取开发指给我的bug-未解决bug数量、待确认bug数
			let res = await this.$utils.api.post({
				url:'/bug/setting',
				result:1
			})
			if(res && res.code == 1){
				let d = res.data.nums
				this.$store.commit('setStore', {
				  name:'unresolvedForDev', // 未解决bug数
				  data: d.unresolved || 0,
				  storage:false
				})
				this.$store.commit('setStore', {
				  name:'waitNotForDev', // 待确认bug数
				  data: d.wait || 0,
				  storage:false
				})
			}
			
			// 获取任务逾期数量
			let r = await this.$utils.api.post({
				url:'/task/setting',
				result:1
			})
			if(r && r.code==1){
				let d = r.data.nums // 逾期数量
				this.$store.commit('setStore', {
				  name:'overdueNum', 
				  data: d.overdue_num || 0,
				  storage:false
				})
			}	
		},
    onTab(idx,label){
			if(label){
				this.$store.commit('setStore',{
					name:'mainLable', 
					data:label
				  })
			}
      if(this.onIndex==idx){
        this.$emit("update:onIndex", -1);
        setTimeout(()=>{
          this.$emit("update:onIndex", idx);
          this.$emit("update:onIdx", 0);
        })
      }else{
        this.$emit("update:onIndex", idx);
        this.$emit("update:onIdx", 0);
      }
	  this.getNum()
    },
    async getUser(){
      var userInfo = await this.$utils.api.get({
        url:'/user/index',
        data:{},
      })
    },
    loginOut(){
      this.$router.replace({path: '/Login'});
      setTimeout(()=>{
        this.$store.commit('setStore', { name:'mainNavIndex', data:null, storage:true });
        this.$store.commit('setStore', { name:'subNavIndex', data:null, storage:true })
        this.$store.commit('setStore', { name:'proId', data:null, storage:true })
        this.$store.commit('setStore', { name:'user', data:null, storage:true })
      },300)
    },
		updatePwd(){
			// 修改密码
			this.isUpdate = true
		},
		async updateOk(){
			var that = this
			if(this.oldPwd == ''){
				this.$utils.msgWar('请输入原密码',1)
				return 
			}
			if(this.newPwd == ''){
				this.$utils.msgWar('请输入新密码',1)
				return 
			}
			if(this.newPwdAgain == ''){
				this.$utils.msgWar('请输入确认新密码',1)
				return 
			}
			if(this.newPwdAgain != this.newPwd){
				this.$utils.msgErr('警告：两次输入的新密码不一致',1.5)
				return 
			}
			let res = await this.$utils.api.post({
			  url:'/user/reset_pwd',
			  data:{
				  password:this.oldPwd,
				  newpassword:this.newPwdAgain
			  },
			  result:1
			})
			if(res.code == 1){
				this.$utils.msgSuc('修改成功',1)
				this.isUpdate = false
				setTimeout(()=>{
					that.loginOut()
				},1000)
			}
			
		},
		updateCancel(){
			this.oldPwd = ''
			this.newPwd = ''
			this.newPwdAgain = ''
			this.isUpdate = false
		}
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.logo{
  width:240px;
  // min-height:30px;
  height: 50px;
  margin-right:30px;
  margin-bottom:0;
  background:#014098 url(../static/logo2.png)  no-repeat;
  background-size: cover;
  span{
    display: none;
  }
}
.navigation{
  flex: 1;
  padding:10px 30px 0;
  display: flex;
  align-items: center;
  .item{
    height:70px;
    width: 12.5%;
    min-width: 150px;
    max-width: 200px;
    display: flex;
    align-items:stretch;
    &:before, &:after{
      content:'';
      display: block;
      position: relative;
      z-index: 1;
      background:#014098;
      width:10px;
      border-radius: 0 0 10px 0;
    }
    &:after{
      border-radius: 0 0 0 10px;
    }
    .lab{
      padding-top:20px;
      border-radius: 10px 10px 0 0;
      line-height:30px;
      flex:1;
      position: relative;
      text-align: center;
      font-size:16px;
      color:#fff;
      cursor: pointer;
    }
    &.on{
      .lab{
        background:#fff;
        color:#333;
        &:after{
          content:'';
          display: block;
          position: absolute;
          left:-10px;
          right:-10px;
          bottom:0;
          height:10px;
          background:#fff;
        }
      }
    }
  }
}
.user{
  display: flex;
  align-items: center;
  color:#fff;
  min-width:160px;
  .avatar{
    width:40px;
    height:40px;
    border-radius: 50%;
    background:#fff;
    margin-right:10px;
    overflow: hidden;
  }
  .name{
    flex:1;
  }
  &:after{
    content:'';
    display: block;
    width:10px;
    height:10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    position: relative;
    top:-3px;
    margin-left:10px;
  }
}
.pwd{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	p{
		padding: 0;
		margin: 0;
		width: 100px;
		text-align: center;
	}
	input{
		width: 350px;
	}
}
</style>
