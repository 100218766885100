<template>
	<!-- 收款-->
	<div class="modal-box">
		<a-form 
			class="third-box" 
			:model="info"
			:label-col="{ span: 8}"
			:wrapper-col="{ span: 12 }"
			@finish="onFinish">
			<div class="first one">
				<p>收款</p>
				<close-outlined @click="close()"/>
			</div>
			<a-form-item
				label="项目名称" 
				name="name" 
				:colon= "false" 
				:rules="[{ required: false }]">
			    {{name}}
			</a-form-item>
			<a-form-item
				label="期数" 
				name="detail_id" 
				:colon= "false" 
				:rules="[{ required: true, message: '请选择期数' }]">
			    <a-dropdown v-if="stateArr.length > 0">
			        <template #overlay>
			            <a-menu selectable @click="handleClick">
			    			<a-menu-item v-for="(item,index) in stateArr" :key="index">{{item.periods}}</a-menu-item>
			            </a-menu>
			        </template>
			        <a-button v-if="stateArr.length > 0">
			    		{{stateArr[stateIndex].periods}}
			            <DownOutlined />
			        </a-button>
			    </a-dropdown>
				<span style="color: #999999;" v-else>请追加期数</span>
			</a-form-item>
			<a-form-item
				label="收款金额" 
				name="pay_amount" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入收款金额' }]">
			    <a-input-number style="width: 250px;" :controls="false" v-model:value="info.pay_amount" placeholder="请输入收款金额" />
			</a-form-item>
			<a-form-item
				label="收款凭证" 
				name="images" 
				:colon= "false"
				:rules="[{ required: false }]">
				 <a-upload
					name="file"
					v-model:file-list="fileList"
					:maxCount='1'
				    list-type="picture"
					:customRequest ="customRequest"
					@remove="remove">
				    <p style="color: #999999;" v-if="fileList.length == 0">点击上传凭证</p>
				  </a-upload>
			</a-form-item>
			<a-form-item
				label="备注" 
				name="remark" 
				:colon= "false" 
				:rules="[{ required: false}]">
			    <a-input v-model:value="info.remark" placeholder="请输入备注" />
			</a-form-item>
			<a-form-item  :wrapper-col="{ offset:8}" style="margin-top: 50px;">
			  <a-button class="clo1" style="margin-right: 50px;" html-type="submit">提交</a-button>
			  <a-button class="clo6" @click="close()">取消</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
	import axios from 'axios';
	export default{
		data(){
			return{
				stateArr:[], // 标签
				stateIndex:0, // 标签下标
				name:'',// 项目名称
				info:{
					ids:0, 
					detail_id:0, // 期数id
					pay_amount:0,//收款金额
					remark:'' , // 备注
					images:'', // 收款凭证
				},
				detail:null ,// 详情
				fileList:[] ,// 图片列表
			}
		},
		computed:{
			updateId(){
				return this.$store.state.updateId
			},
			user() {
			  return this.$store.state.user
			},
		},
		async created() {
			this.getInfo()
		},
		methods:{
			close(){
				// 重置收款数据
				this.$store.commit('setCollectInfo',{})
				// 关闭弹窗
				this.$store.commit('setIsCollection',0)
			},
			handleClick(e){
				// 选择期数
				this.stateIndex = e.key
				this.info.detail_id = this.stateArr[e.key].id // 期数id
				this.info.pay_amount = this.stateArr[e.key].to_paid // 待收款金额
			},
			async getInfo(){
				// 详情
				let res = await this.$utils.api.post({
					url:'/contract/getRow',
					data:{
						ids:this.$store.state.updateId
					},
					result:1
				})
				if(res.code == 1){
					this.detail = res.data
					this.info.ids = res.data.id
					let arr = res.data.original_details
					let arr2 = res.data.append_details
					this.stateArr = arr.concat(arr2)
					if(arr.length > 0){
						this.info.detail_id =  arr[0].id //期数id
						this.info.pay_amount =  arr[0].to_paid  // 待收款金额
					}
					this.name = res.data.project_name
				}
			},
			async customRequest (e) {
				// 执行图片上传
				let that = this
				// 上传图片
				let res = await this.$utils.api.upLoad(e)
				if(res.code == 1){
					setTimeout(()=>{
						that.$utils.msgSuc('上传成功')
						that.fileList[0].status = 'done' // 上传成功修改文件状态
						that.fileList[0].thumbUrl = res.data.fullurl // 图片封面预览
						that.info.images = res.data.fullurl // 收款凭证
					},500)
				}
			},
			remove(e){
				// 删除图片
				this.fileList= [] // 只上传一张时直接清空图片列表
				this.info.images = '' // 重置为空
			},
			async onFinish(e){
				// 提交-验证完成后
				if(this.info.images == ''){
					this.$utils.msgWar('请上传收款凭证')
					return
				}
				let res = await this.$utils.api.post({
					url:'/contract/collection',
					data:this.info,
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('操作成功')
					this.$store.commit('setIsFresh',1) // 刷新列表
					this.close() // 关闭
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 550px;
			height:550px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				margin-bottom: 50px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
		}
	}
</style>