import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Third',
    name: 'Third',
    component: () => import('../views/third.vue'),
  },
  // {
  //   path: '/abc',
  //   name: 'abc',
  //   component: () => import('../views/abc.vue'),
  // },
  {
    path: '/Document',
    name: 'Document',
    component: () => import('../views/Document.vue'),
  },
	{
	  path: '/Product',
	  name: 'Product',
	  component: () => import('../views/Product.vue')
	},
	{
	  path: '/ProductNeed',
	  name: 'ProductNeed',
	  component: () => import('../views/procducNeed.vue'),
	},
	{
	  path: '/ProductDetail',
	  name: 'ProductDetail',
	  component: () => import('../views/productDetail.vue'),
	},
	{
	  path: '/ProductAddDetail',
	  name: 'ProductAddDetail',
	  component: () => import('../views/productAddDetail.vue'),
	},
	{
	  path: '/ProductRecord',
	  name: 'ProductRecord',
	  component: () => import('../views/productRecord.vue'),
	},
	{
	  path: '/ForwardBug',
	  name: 'ForwardBug',
	  component: () => import('../views/forwardBug.vue'),
	},
	{
	  path: '/ProductMaintain',
	  name: 'ProductMaintain',
	  component: () => import('../views/productMaintain.vue'),
	},
	{
	  path: '/SubmitBug',
	  name: 'SubmitBug',
	  component: () => import('../views/submitBug.vue'),
	},
	{
	  path: '/ProductThird',
	  name: 'ProductThird',
	  component: () => import('../views/productThird.vue'),
	},
	{
	  path: '/TaskProject',
	  name: 'TaskProject',
	  component: () => import('../views/taskProject.vue'),
	},
	{
	  path: '/TaskProjectDetail',
	  name: 'TaskProjectDetail',
	  component: () => import('../views/taskProjectDetail.vue'),
	},
	{
	  path: '/TaskProjectAdd',
	  name: 'TaskProjectAdd',
	  component: () => import('../views/taskProjectAdd.vue'),
	},
	{
	  path: '/TaskProjectAddDetail',
	  name: 'TaskProjectAddDetail',
	  component: () => import('../views/taskProjectAddDetail.vue'),
	},
	{
	  path: '/TaskProjectNeed',
	  name: 'TaskProjectNeed',
	  component: () => import('../views/taskProjectNeed.vue'),
	},
	{
	  path: '/TaskProjectNeedDetail',
	  name: 'TaskProjectNeedDetail',
	  component: () => import('../views/taskProjectNeedDetail.vue'),
	},
	{
	  path: '/ContractSituation',
	  name: 'ContractSituation',
	  component: () => import('../views/contractSituation.vue'),
	},
	{
	  path: '/TaskCreate',
	  name: 'TaskCreate',
	  component: () => import('../views/taskCreate.vue'),
	},
	{
	  path: '/TaskProjectTestCase',
	  name: 'TaskProjectTestCase',
	  component: () => import('../views/taskProjectTestCase.vue'),
	},
	{
	  path: '/TestCaseDetail',
	  name: 'TestCaseDetail',
	  component: () => import('../views/testCaseDetail.vue'),
	},
	{
	  path: '/TaskProjectThird',
	  name: 'TaskProjectThird',
	  component: () => import('../views/taskProjectThird.vue'),
	},
	{
	  path: '/TaskProjectBug',
	  name: 'TaskProjectBug',
	  component: () => import('../views/taskProjectBug.vue'),
	},
	{
	  path: '/TaskProjectMember',
	  name: 'TaskProjectMember',
	  component: () => import('../views/taskProjectMember.vue'),
	},
	{
	  path: '/TaskProjectManagement',
	  name: 'TaskProjectManagement',
	  component: () => import('../views/taskProjectManagement.vue'),
	},
	{
	  path: '/DevelopmentDetail',
	  name: 'DevelopmentDetail',
	  component: () => import('../views/developmentDetail.vue'),
	},
	{
	  path: '/DevelopmentAddDetail',
	  name: 'DevelopmentAddDetail',
	  component: () => import('../views/developmentAddDetail.vue'),
	},
	{
	  path: '/DevelopmentTask',
	  name: 'DevelopmentTask',
	  component: () => import('../views/developmentTask.vue'),
	},
	{
	  path: '/DevelopmentTaskAdd',
	  name: 'DevelopmentTaskAdd',
	  component: () => import('../views/developmentTaskAdd.vue'),
	},
	{
	  path: '/DevelopmentTaskAll',
	  name: 'DevelopmentTaskAll',
	  component: () => import('../views/developmentTaskAll.vue'),
	},
	{
	  path: '/DevelopmentTaskAllAdd',
	  name: 'DevelopmentTaskAllAdd',
	  component: () => import('../views/developmentTaskAllAdd.vue'),
	},
	{
	  path: '/DevelopmentContractSituation',
	  name: 'DevelopmentContractSituation',
	  component: () => import('../views/developmentContractSituation.vue'),
	},
	{
	  path: '/DevelopmentBug',
	  name: 'DevelopmentBug',
	  component: () => import('../views/developmentBug.vue'),
	},
	{
	  path: '/DevelopmentBugDetail',
	  name: 'DevelopmentBugDetail',
	  component: () => import('../views/developmentBugDetail.vue'),
	},
	{
	  path: '/DevelopmentTestCase',
	  name: 'DevelopmentTestCase',
	  component: () => import('../views/developmentTestCase.vue'),
	},
	{
	  path: '/DevelopmentTestCaseDetail',
	  name: 'DevelopmentTestCaseDetail',
	  component: () => import('../views/developmentTestCaseDetail.vue'),
	},
	{
	  path: '/TheTestCase',
	  name: 'TheTestCase',
	  component: () => import('../views/theTestCase.vue'),
	},
	{
	  path: '/TheTestCaseDetail',
	  name: 'TheTestCaseDetail',
	  component: () => import('../views/theTestCaseDetail.vue'),
	},
	{
	  path: '/TheTestCaseAdd',
	  name: 'TheTestCaseAdd',
	  component: () => import('../views/theTestCaseAdd.vue'),
	},
	{
	  path: '/TheTestCaseBug',
	  name: 'TheTestCaseBug',
	  component: () => import('../views/theTestCaseBug.vue'),
	},
	{
	  path: '/TheTestCaseNeedDetail',
	  name: 'TheTestCaseNeedDetail',
	  component: () => import('../views/theTestCaseNeedDetail.vue'),
	},
	{
	  path: '/TheTestCaseNeed',
	  name: 'TheTestCaseNeed',
	  component: () => import('../views/theTestCaseNeed.vue'),
	},
	{
	  path: '/SolutionSales',
	  name: 'SolutionSales',
	  component: () => import('../views/solutionSales.vue'),
	},
	{
	  path: '/SolutionCustomer',
	  name: 'SolutionCustomer',
	  component: () => import('../views/solutionCustomer.vue'),
	},
	{
	  path: '/SolutionCollection',
	  name: 'SolutionCollection',
	  component: () => import('../views/solutionCollection.vue'),
	},
	{
	  path: '/SolutionThird',
	  name: 'SolutionThird',
	  component: () => import('../views/solutionThird.vue'),
	},
	{
	  path: '/SolutionNeed',
	  name: 'SolutionNeed',
	  component: () => import('../views/solutionNeed.vue'),
	},
	{
	  path: '/SolutionNeedRecord',
	  name: 'SolutionNeedRecord',
	  component: () => import('../views/solutionNeedRecord.vue'),
	},
	{
	  path: '/SolutionCustomerDetail',
	  name: 'SolutionCustomerDetail',
	  component: () => import('../views/solutionCustomerDetail.vue'),
	},
	{
	  path: '/ReviewNeed',
	  name: 'ReviewNeed',
	  component: () => import('../views/reviewNeed.vue'),
	},
	{
	  path: '/ReviewTestCase',
	  name: 'ReviewTestCase',
	  component: () => import('../views/reviewTestCase.vue'),
	},
	{
	  path: '/ReviewNeedDetail',
	  name: 'ReviewNeedDetail',
	  component: () => import('../views/reviewNeedDetail.vue'),
	},
	{
	  path: '/ReviewTestCaseDetail',
	  name: 'ReviewTestCaseDetail',
	  component: () => import('../views/reviewTestCaseDetail.vue'),
	},
	{
	  path: '/SelfCheck',
	  name: 'SelfCheck',
	  component: () => import('../views/selfCheck.vue'),
	}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base:'/dist/',
  routes,
});

export default router;
