<template>
	<!-- 合同收款 -->
	<div class="modal-box">
		<a-form 
			class="third-box" 
			:model="info"
			:label-col="{ span: 8}"
			:wrapper-col="{ span: 12 }"
			@finish="onFinish">
			<div class="first one">
				<p>合同分期收款</p>
				<close-outlined @click="close()"/>
			</div>
			<a-form-item
				label="项目名称" 
				:colon= "false" 
				:rules="[{ required: false}]">
			    {{collectInfo.name}}
			</a-form-item>
			<a-form-item
				label="期数" 
				name="periods" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入期数' }]">
			    <a-input v-model:value="info.periods" placeholder="请输入期数" />
			</a-form-item>
			<a-form-item
				label="收款比例(%)" 
				name="pay_ratio" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入收款比例' }]">
			    <a-input-number style="width: 250px;" :controls="false" v-model:value="info.pay_ratio" placeholder="请输入收款比例" @change="changeRatio"/>
			</a-form-item>
			<a-form-item
				label="收款金额" 
				name="pay_money" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入收款金额' }]">
			    <a-input type="Number" v-model:value="info.pay_money" placeholder="请输入收款金额" disabled/>
			</a-form-item>
			<a-form-item  :wrapper-col="{ offset:8}" style="margin-top: 50px;">
			  <a-button class="clo1" style="margin-right: 50px;" html-type="submit">提交</a-button>
			  <a-button class="clo6" @click="close()">取消</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				info:{
					ids:0, // 列id
					periods:'' ,// 期数
					pay_ratio:'',// 比例
					pay_money:'' // 金额
				}
			}
		},
		computed:{
			collectInfo(){
				// 获得列id
				this.info.ids = this.$store.state.collectInfo.id
				return this.$store.state.collectInfo
			}
		},
		methods:{
			close(){
				// 重置追加信息
				this.$store.commit('setCollectInfo',{})
				// 关闭
				this.$store.commit('setIsAddContract',0)
			},
			changeRatio(val){
				let count = (val/100) * this.collectInfo.money
				this.info.pay_money = count.toFixed(2)
			},
			async onFinish(e){
				// 提交
				let res = await this.$utils.api.post({
					url:'/contract/add_periods',
					data:this.info,
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('添加成功')
					this.$store.commit('setIsFresh',1) // 刷新
					this.close() // 关闭
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 500px;
			height: 400px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				margin-bottom: 20px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
		}
	}
</style>