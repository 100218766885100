<template>
  <GModule class="menu">
    <!-- <a-menu
  		v-model:openKeys="openKeys"
  		v-model:selectedKeys="selectedKeys"
  		mode="inline"
  		class="um-scroll">
    		<a-menu-item key="father" v-if="onEdit">+ 添加端模块</a-menu-item>
    		<template v-for="(item,index) in menuList">
          <template v-if="item.childlist.length||onEdit">
      			<a-sub-menu :key="item.id">
      				<template #title>{{item.name}}</template>
              <a-menu-item :key="'father-'+item.id" v-if="onEdit">+ 添加该端功能模块</a-menu-item>
              <template v-for="(itm,ind) in item.childlist">
                <a-sub-menu v-if="itm.childlist.length" :key="itm.id">
                  <template #title>{{itm.name}}</template>
                  <a-menu-item :key="'father-'+itm.id" v-if="onEdit">+ 添加该功能需求点</a-menu-item>
                  <a-menu-item v-for="(it,ix) in itm.childlist" :disabled="onEdit" :key="it.id" v-text="it.name"></a-menu-item>
                </a-sub-menu>
          			<a-menu-item v-else :key="itm.id" :disabled="onEdit" v-text="itm.name"></a-menu-item>
              </template>
      			</a-sub-menu>
          </template>
    		  <a-menu-item v-else :key="item.id" v-text="item.name"></a-menu-item>
    		</template>
    		<a-button class="btn clo1" @click="onEdit=!0" v-if="typeOff('add')&&!onEdit">维护模块</a-button>
        <a-modal v-model:visible="onEdit"
          title="模块维护"
          wrapClassName="sider"
          :maskStyle="{'left':'320px'}" 
          @ok="onEdit=!1,newlist=[]">
          <div class="add-box">
            <div class="add">
              <a-breadcrumb separator=">">
                  <a-breadcrumb-item>模块维护</a-breadcrumb-item>
                  <a-breadcrumb-item v-for="item in indexList">{{item.name}}</a-breadcrumb-item>
               </a-breadcrumb>
               <div class="child" v-for="(item,index) in setupList" :key="index">
                <a-input v-model:value="item.name"/>
                <a-button class="btn clo3" v-if="typeOff('add')" @click="setItem(item)">保存</a-button>
                <a-popconfirm
                  title="确认是否删除?"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="delItem(item.id)">
                  <a-button type="danger" class="btn">删除</a-button>
                  </a-popconfirm>
               </div>
               <div class="child" v-for="(item,index) in newlist" :key="index">
                <a-input v-model:value="item.name"/>
                <a-button class="btn clo3" @click="addItem(item,idx)">保存</a-button>
                <a-popconfirm
                    title="确认是否删除?"
                    ok-text="确认"
                    cancel-text="取消"
                    @confirm="delItem(null,index)">
                    <a-button type="danger" class="btn">删除</a-button>
                  </a-popconfirm>
               </div>
               <div class="add-btn">
                <a-button shape="circle" @click="addItem()">
                  <template #icon>
                    <plus-outlined />
                  </template>
                </a-button>
               </div>
            </div>
          </div>
        </a-modal>
    </a-menu> -->
    <!-- <div class="um-scroll">
      <a-tree class="treeList" v-model:selectedKeys="selectedKeys"
        :expanded-keys="openKeys"
        :tree-data="menuList" 
        @expand="handleExpand">
        <template #title="{ title, key }">
          <span class="item">{{ title }}</span>
        </template>
      </a-tree>
        <a-button class="btn clo1" @click="onEdit=!0" v-if="typeOff('add')&&!onEdit">维护模块</a-button>
        <a-modal v-model:visible="onEdit"
          title="模块维护"
          wrapClassName="sider"
          :maskStyle="{'left':'280px'}" 
          @ok="onEdit=!1,newlist=[]">
          <div class="add-box">
            <div class="add">
              <a-breadcrumb separator=">">
                  <a-breadcrumb-item>维护模块</a-breadcrumb-item>
                  <template v-for="(item,idx) in indexList">
                    <a-breadcrumb-item v-if="idx<2">{{item.name}}</a-breadcrumb-item>
                  </template>
               </a-breadcrumb>
               <div class="child" v-for="(item,index) in setupList" :key="index">
                <a-input v-model:value="item.name"/>
                <a-button class="btn clo3" v-if="typeOff('add')" @click="setItem(item)">保存</a-button>
                <a-popconfirm
                  title="确认是否删除?"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="delItem(item.id)">
                  <a-button type="danger" class="btn">删除</a-button>
                  </a-popconfirm>
               </div>
               <div class="child" v-for="(item,index) in newlist" :key="index">
                <a-input v-model:value="item.name"/>
                <a-button class="btn clo3" @click="addItem(item,index)">保存</a-button>
                <a-popconfirm
                    title="确认是否删除?"
                    ok-text="确认"
                    cancel-text="取消"
                    @confirm="delItem(null,index)">
                    <a-button type="danger" class="btn">删除</a-button>
                  </a-popconfirm>
               </div>
               <div class="add-btn">
                <a-button shape="circle" @click="addItem()">
                  <template #icon>
                    <plus-outlined />
                  </template>
                </a-button>
               </div>
            </div>
          </div>
        </a-modal>
    </div> -->
	
	<div class="um-scroll">
	  <a-tree class="treeList" v-model:selectedKeys="selectedKeys"
	    :expanded-keys="openKeys"
	    :tree-data="menuList" 
	    @expand="handleExpand">
	    <template #title="{ title, key }">
			<!-- <span class="item">{{ title }}</span> -->
			<a-tooltip placement="right" color="#ffffff">
			    <template #title>
			      <span style="color: #666666;">{{ title }}</span>
			    </template>
			    <p class="item ellipsis-row" style="margin: 0;">{{ title }}</p>
			</a-tooltip>
	    </template>
	  </a-tree>
	    <a-button class="btn clo1" @click="openModal()" v-if="typeOff('add')&&!isOpen">维护模块</a-button>
	    <a-modal v-model:visible="isOpen"
	      title="模块维护"
	      wrapClassName="sider"
	      :maskStyle="{'left':'280px'}" 
		  okText="保存"
	      @ok="okModal"
		  @cancel="cancelModal">
	      <div class="add-box">
	        <div class="add">
	          <a-breadcrumb separator=">">
	              <a-breadcrumb-item>维护模块</a-breadcrumb-item>
	              <template v-for="(item,idx) in indexList">
	                <a-breadcrumb-item v-if="idx<2">{{item.name}}</a-breadcrumb-item>
	              </template>
	           </a-breadcrumb>
	           <div class="child" v-for="(item,index) in setupList" :key="index">
	            <a-input v-model:value="item.name"/>
				<a-input v-model:value="item.weigh" placeholder="排序" style="width: 100px;margin-left: 10px;text-align: center;"/>
	            <!-- <a-button class="btn clo3" v-if="typeOff('add')" @click="setItem(item)">修改</a-button> -->
	            <a-popconfirm
	              title="确认是否删除?"
	              ok-text="确认"
	              cancel-text="取消"
	              @confirm="delItem(item.id)">
	              <a-button type="danger" class="btn">删除</a-button>
	              </a-popconfirm>
	           </div>
	           <div class="child" v-for="(item,index) in newlist" :key="index">
	            <a-input v-model:value="item.name"/>
				<a-input v-model:value="item.weigh" placeholder="排序" style="width: 100px;margin-left: 10px;text-align: center;"/>
	            <a-popconfirm
	                title="确认是否删除?"
	                ok-text="确认"
	                cancel-text="取消"
	                @confirm="delItem(null,index)">
	                <a-button type="danger" class="btn">删除</a-button>
	              </a-popconfirm>
	           </div>
	           <div class="add-btn">
	            <a-button shape="circle" @click="addItem('添加')">
	              <template #icon>
	                <plus-outlined />
	              </template>
	            </a-button>
	           </div>
	        </div>
	      </div>
	    </a-modal>
	</div>

  </GModule>
</template>

<script>

export default {
  name: 'Sider',
  data(){
    return {
      onIndex: 0,
      navList:[{
        name:'产品'
      },{
        name:'项目'
      },{
        name:'开发'
      },{
        name:'测试'
      },{
        name:'解决方案'
      },{
        name:'文档',
        url:''
      }],
  	  openKeys:[],
      selectedKeys:[],
      menuList:[],
      onEdit:!1,
      newlist:[],
	  isOpen:false
    }
  },
  props:['types'],
  computed: {
    user() {
      return this.$store.state.user
    },
  	typeOff(){
  		return (name) => {
        return this.types&&this.types.child.find(item=>item.name=='api/modular/'+name)
      }
  	},
    proId(){
      return this.$store.state.proId  
    },
    indexList(){
      let data = [];
      if(this.selectedKeys&&this.selectedKeys[0]){
        let id = this.selectedKeys[0];
        this.menuList.forEach(itm => {
          if(itm.id == id){
            data = [itm];
          }else{
            itm.childlist.forEach(it => {
              if(it.id == id){
                data = [itm,it];
              }else{
                it.childlist.forEach(i => {
                  if(i.id == id){
                    data = [itm,it,i];
                  }
                });
              }
            });
          }
        });
      }
      return data
    },
    setupList(){
      let list = [].concat(this.menuList);
      if(this.selectedKeys&&this.selectedKeys[0]){
        let lists = this.menuList.find(itm=>itm.id==this.selectedKeys[0])&&this.menuList.find(itm=>itm.id==this.selectedKeys[0]).childlist;
        if(!lists){
          this.menuList.forEach(item=>{
            let listsd = item.childlist.find(itm=>{
              return itm.id==this.selectedKeys[0]||itm.childlist.find(i=>i.id==this.selectedKeys[0])
            })
            if(listsd)lists = listsd.childlist;
          })
        }
        list = lists || list;
      }
	  console.log("模块列表",list)
      return list
    },
  },
  watch:{
    proId(){
      if(this.proId){
        this.getList() // 初始化列表
        this.openKeys = [];
        this.selectedKeys = [];
        this.$store.commit('setStore', {
          name:'selectedMemu', 
          data: []
        })
      }
    },
	  selectedKeys(val,oldvalue){
			this.newlist = []
      const arr = [].concat(this.indexList);
      this.$store.commit('setStore', {
        name:'selectedMemu', 
        data: arr
      });
      if(arr.length==3){
        this.$store.commit('setStore', {
          name: 'selectedKeys', 
          data: this.selectedKeys[0]
        });
      }else{
        this.$store.commit('setStore', {
          name: 'selectedKeys', 
          data: 0
        });
      }
	  },
  },
  created(){
    this.selectedKeys = [this.$store.state.selectedKeys];
	  this.getList() // 初始化列表
  },
  methods:{
	  openModal(){
		  // 弹出模态框
		this.isOpen = true  
	  },
	  cancelModal(){
		  // 关闭模态框
		  this.newlist = [] // 清空
		  this.isOpen = false  
	  },
	  async okModal(){
		  // 保存
		  for(let k in this.setupList){
				await this.setItem(this.setupList[k]) // 调用修改
		  }
		  if(this.newlist&&this.newlist.length > 0){
			  for(let i in this.newlist){
				  if(this.newlist[i].name != ''){
					 await this.addItem(this.newlist[i],i)	
				  }
			  }
		  }
		  this.getList()
		  this.cancelModal() 
	  },
  	async getList(){
      if((!this.user)||(!this.proId))return;
  		let res = await this.$utils.api.get({
  			url:'/modular/lists',
  			result:1
  		})
  		if(res.code == 1){
        var list = res.data.modular.map((item,index)=>{
          item.title = item.name;
          item.key = item.id;
          item.ids = [item.id];
          item.children = item.childlist.map((ite,indx)=>{
            ite.title = ite.name;
            ite.key = ite.id;
            ite.ids = [ite.id];
            item.ids.push(ite.id)
            ite.children = ite.childlist.map((it,idx)=>{
              it.title = it.name;
              it.key = it.id;
              it.ids = [it.id];
              item.ids.push(it.id)
              ite.ids.push(it.id)
              return it
            })
            return ite
          })
          return item
        })
  			this.menuList = list;
  		}
  	},
  	open(){
  		// 弹出维护模块
  		this.$router.push({path:'/ProductMaintain'})
  	},
    handleExpand(keys, { expanded, node }){
      // node.parent add from 3.0.0-alpha.10
      const tempKeys = ((node.parent ? node.parent.children : this.menuList) || []).map(({
        key,
      }) => key);

      if (expanded) {
	    // 展开
        this.openKeys = this.difference(keys, tempKeys).concat(node.key);
      } else {
		// 收起
        // this.openKeys = [keys];
		this.openKeys = this.difference(keys, tempKeys);
      }
    },
    difference(arr,err){
      var idx = -1;
      err.forEach(i=>{
        if(idx==-1){
          idx = arr.findIndex(j=>{
            return i==j
          });
        }
      })
      if(idx>-1){
        arr.splice(idx, 1);
        return this.difference(arr,err)
      }else{
        return arr
      }
    },
    async addItem(item,idx){
      if(item&&item!='添加'){ 
        let idx = this.indexList.length-1;
        if(idx>1)idx=1;
        // let md = this.indexList[idx];
        let res = await this.$utils.api.post({
          url:'/modular/add',
          data:{
            row:{
              name: item.name,
              pid: this.indexList[idx]?this.indexList[idx].id:0,
              weigh:item.weigh
            }
          },
          result:1
        })
        if(res.code==1){
			// if(this.newlist.length>1){
			//   this.newlist.splice(idx,1);
			// }else{
			//   this.newlist = [];
			// }
          this.$utils.msgSuc('保存成功！')
        };
      }else if(item == '添加'){
        this.newlist.push({
          name:''
        })
      }
    },
    async delItem(item,idx){
      if(item){
        let res = await this.$utils.api.post({
          url:'/modular/del',
          data:{ids:item},
          result:1
        })
        if(res.code==1)this.getList(),this.$utils.msgSuc('删除成功！');
      }else{
        this.newlist.splice(idx,1);
        this.$utils.msgSuc('删除成功！');
      }
    },
    async setItem(item){
	  let idx = this.indexList.length-1;
	  if(idx>1)idx=1;
      let res = await this.$utils.api.post({
        url:'/modular/edit',
        data:{
          row:{
            'name': item.name,
            'pid':this.indexList[idx]?this.indexList[idx].id:'',
            'weigh':item.weigh
          },
          'ids': item.id
        },
        result:1
      })
      // if(res.code==1)this.getList(),this.$utils.msgSuc('修改成功！');
    }
  }
};
</script>

<style scoped lang="scss">
.menu{
  overflow: hidden;
  .um-scroll{
    height:100%;
  }
  .btn{
    margin:20px 20px 10px;
    width:210px;
  }
}
  .add-box{
    display: flex;
    justify-content: center;
    max-height: 550px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px; // 控制滚动条
    }
    &::-webkit-scrollbar-thumb{
      background-color: #bfbfbf; // 滚动条颜色
    }
    &:hover{
      &::-webkit-scrollbar {
        width: 1px;
      }
    }
    .add{
      width: 500px;
      .child{
        display: flex;
        align-items: center;
        padding-top: 20px;
        .btn{
          margin-left: 20px;
        }
      }
    }
    .add-btn{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 140px;
      .ant-btn-icon-only{
        width: 45px !important;
        height: 45px !important;
        padding:0 !important;
      }
    }
  }

</style>
