<template>
	<!-- 追加 -->
	<div class="modal-box">
		<a-form 
			class="third-box" 
			:model="info"
			:label-col="{ span: 8}"
			:wrapper-col="{ span: 12 }"
			@finish="onFinish">
			<div class="first one">
				<p>追加</p>
				<close-outlined @click="close()"/>
			</div>
			<a-form-item
				label="项目名称" 
				:colon= "false" 
				:rules="[{ required: false}]">
			    {{collectInfo.name}}
			</a-form-item>
			<a-form-item
				label="期数" 
				name="periods" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入期数' }]">
			    <a-input v-model:value="info.periods" placeholder="请输入期数" />
			</a-form-item>
			<a-form-item
				label="追加金额" 
				name="pay_money" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入追加金额' }]">
			    <a-input-number style="width: 250px;" :controls="false" v-model:value="info.pay_money" placeholder="请输入追加金额" />
			</a-form-item>
			<a-form-item  :wrapper-col="{ offset:8}" style="margin-top: 50px;">
			  <a-button class="clo1" style="margin-right: 50px;" html-type="submit">提交</a-button>
			  <a-button class="clo6" @click="close()">取消</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				info:{
					ids:0, // 列id
					periods:'' ,// 期数
					pay_money:'' // 追加金额
				}
			}
		},
		computed:{
			collectInfo(){
				// 回显追加金额
				this.info.ids = this.$store.state.collectInfo.id
				// this.info.pay_money = this.$store.state.collectInfo.money
				return this.$store.state.collectInfo
			}
		},
		methods:{
			close(){
				// 重置追加信息
				this.$store.commit('setCollectInfo',{})
				// 关闭
				this.$store.commit('setIsAddCollect',0)
			},
			async onFinish(e){
				// 提交
				let res = await this.$utils.api.post({
					url:'/contract/append_periods',
					data:this.info,
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('追加成功')
					this.$store.commit('setCollectInfo',{id:this.info.ids,money:this.info.pay_money})
					this.$store.commit('setIsAddCollectFresh',1) // 刷新
					this.$store.commit('setIsAddCollect',0) // 关闭
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 500px;
			height: 350px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				margin-bottom: 20px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
		}
	}
</style>