<template>
  <!-- <div v-if="!abc" class="login" @click="abc=1">
    登录页面
  </div> -->
  <a-config-provider :locale="locale" :getPopupContainer="getPopupContainer">
    <div class="bbbd">
      <template v-if="user">
        <Navigation class="g-header" :navList="navLists" v-model:onIndex="onIndex" v-model:onIdx="onIdx" v-if="navLists.length > 0"></Navigation>
        <template v-if="navLists[onIndex]&&navLists[onIndex].rules&&navLists[onIndex].rules.length">
          <Tabs class="g-tabs" :tabs="navLists[onIndex].rules" :navname="navLists[onIndex].groups" v-model:onIdx="onIdx"></Tabs>
        </template>
        <Sider class="g-sider" v-if="hasPro" :types="{...hasPro}"></Sider>
        <Message class="g-msg" v-if="navLists[onIndex]&&(navLists[onIndex].msg||navLists[onIndex].urls)" :class="(msgOpen||history)?'open':''" :isMsgs="navLists[onIndex]&&navLists[onIndex].msg" :isUrls="navLists[onIndex]&&navLists[onIndex].urls" :isHistory="history">
          <div class="onoff" v-if="!history" :class="msgOpen==1?'on':''" @click="msgOpen?msgOpen=0:msgOpen=1"></div>
        </Message>



    		<Swiper v-if="isSwiper"></Swiper>
    		<DownloadImgs v-if="isImgCheck"></DownloadImgs>
    		<DownloadImgsOther v-if="isImgOther"></DownloadImgsOther>
    		<ThirdAdd v-if="isThirdAdd"></ThirdAdd>
    		<MemberAdd v-if="isMemberAdd"></MemberAdd>
    		<ManagerAdd v-if="isManagerAdd"></ManagerAdd>
    		<CustomerAdd v-if="isCustomerAdd"></CustomerAdd>
    		<RecordAdd v-if="isRecordAdd"></RecordAdd>
    		<Collection v-if="isCollection"></Collection>
    		<Signing :signInfo="signInfo" v-if="isSigning"></Signing>
    		<AddCollect v-if="isAddCollect"></AddCollect>
    		<Invoice v-if="isInvoice"></Invoice>
    		<CollectUpdate v-if="isCollectUpdate"></CollectUpdate>
    		<AddContract v-if="isAddContract"></AddContract>
      </template>
      <router-view class="g-body"></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import Navigation from '@/components/Navigation.vue';// 主导航
import Tabs from '@/components/Tabs.vue';// 次导航
import Sider from '@/components/Sider.vue';// 需求左侧边栏
import Message from '@/components/Message.vue';// 通知消息及链接右侧边栏
import zhCN from 'ant-design-vue/es/locale/zh_CN';// ant-design中文

import Swiper from '@/components/Swiper.vue'
import DownloadImgs from '@/components/DownloadImgs.vue'
import DownloadImgsOther from '@/components/DownloadImgsOther.vue'
import ThirdAdd from '@/components/ThirdAdd.vue'
import MemberAdd from '@/components/MemberAdd.vue'
import ManagerAdd from '@/components/ManagerAdd.vue'
import CustomerAdd from '@/components/CustomerAdd.vue'
import RecordAdd from '@/components/RecordAdd.vue'
import Signing from '@/components/Signing.vue'
import Collection from '@/components/Collection.vue'
import AddCollect from '@/components/AddCollect.vue'
import Invoice from '@/components/Invoice.vue'
import CollectUpdate from '@/components/CollectUpdate.vue'
import AddContract from '@/components/AddContract.vue'
import $axios from '@/request';
export default {
  data(){
    return {
      msgOpen:0,
      locale:zhCN,
      navList:[],
      onIndex:-1,
      onIdx:-1,
      name:'',
      hasProArr:[],// 'third'
	  isRule:false
    }
  },
  components:{
    Navigation,
    Tabs,
    Sider,
    Message,
    Swiper,
    DownloadImgs,
    DownloadImgsOther,
    ThirdAdd,
    MemberAdd,
    ManagerAdd,
    CustomerAdd,
    RecordAdd,
    Signing,
    Collection,
    AddCollect,
    Invoice,
    CollectUpdate,
    AddContract
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    history(){
      return this.$store.state.history
    },
    navLists(){
      var that  = this,
        newList = [];
      this.navList.forEach(item=>{
        if(item.rules.length){
          // .'api/urls'
          item.urls = item.rules.find(ite=>ite.name=='api/urls');
          item.msg = item.rules.find(ite=>ite.name=='api/msg');
          item.rulesBack = [].concat(item.rules);
		  let names = [
			  {name:'项目经理',key:'project'},
			  {name:"产品经理",key:'product'},
			  {name:"开发",key:'develop'},
			  {name:"测试",key:'test'},
			  {name:"解决方案经理",key:'solution'},
			  {name:"需求审阅",key:'review'},
			  {name:"用例审阅",key:'review_case'},
			  {name:"文档",key:''},
			  {name:"自查表",key:''}]
		  item.groups = names.find(ite=>ite.name == item.role).key
          item.rules = that.getTypeSetup(item.rules,item.role);
          newList.push(item)
        }
      })
      return newList
    },
    hasPro(){
      return (!this.$store.state.hideSider)&&this.navLists[this.onIndex]&&this.navLists[this.onIndex].rules&&this.navLists[this.onIndex].rules[this.onIdx]&&this.navLists[this.onIndex].rules[this.onIdx].hasPro
    },
    onNav(){
      return (this.onIndex>=0&&this.onIdx>=0?[this.onIndex, this.onIdx]:null)
    },

    // 弹窗
      isSwiper(){
      	return this.$store.state.isSwiper
      },
      isImgCheck(){
      	return this.$store.state.isImgCheck
      },
      isImgOther(){
      	return this.$store.state.isImgOther
      },
      isThirdAdd(){
      	return this.$store.state.isThirdAdd
      },
      isMemberAdd(){
      	return this.$store.state.isMemberAdd
      },
      isManagerAdd(){
      	return this.$store.state.isManagerAdd
      },
      isCustomerAdd(){
      	return this.$store.state.isCustomerAdd
      },
      isRecordAdd(){
      	return this.$store.state.isRecordAdd
      },
      isSigning(){
      	return this.$store.state.isSigning
      },
      signInfo(){
      	return this.$store.state.signInfo
      },
      isCollection(){
      	return this.$store.state.isCollection
      },
      isAddCollect(){
      	return this.$store.state.isAddCollect
      },
      isInvoice(){
      	return this.$store.state.isInvoice
      },
      isCollectUpdate(){
      	return this.$store.state.isCollectUpdate
      },
      isAddContract(){
      	return this.$store.state.isAddContract
      },
	  params(){
		  return this.$store.state.params
	  }
    // 弹窗
  },
  watch:{
    user() {
      if(this.user){
        this.getRules()
      }else{
        this.navList = [];
      }
    },
    onIndex(){
      this.$store.commit('setStore', { name:'mainNavIndex', data:this.onIndex, storage:true });
      this.onIdx = 0;
      this.$store.commit('setStore', { name:'subNavIndex', data:0 })
      this.$store.commit('setStore',{ name:'hideSider', data:0 })
      this.$store.commit('setStore',{ name:'history', data:null })
	  
	  // console.log(this.onIndex,'dddd',this.navList)
	  // if(this.onIndex != -1 && this.navList.length > 0){
		 //  let num = this.navList.length 
		 //   this.$store.commit('setStore',{ 
			//     name:'mainLable', 
			// 	data:this.onIndex == num ?'document' : this.onIndex == num+1 ? 'selfs' : this.navList[this.onIndex].label ,
		 //   })
	  // }
    },
    onIdx(){
      this.$store.commit('setStore', { name:'subNavIndex', data:this.onIdx })
      this.$store.commit('setStore',{ name:'hideSider', data:0 })
      this.$store.commit('setStore',{ name:'history', data:null })
    },
    onNav(){
      if(this.onNav){
        let page = this.navLists[this.onNav[0]]?this.navLists[this.onNav[0]].rules[this.onNav[1]]:null;
        if(page){
          this.$store.commit('setStore', { name:'params', data:page.child || [] })
          // this.$router.replace({name:page.name})
		  
		  // 页面跳转给接口header添加label参数
		  let label = ''
		  if(this.onIndex != -1 && this.navList.length > 0){
			  let num = this.navList.length 
			  label = this.onIndex == num ?'document' : this.onIndex == num+1 ? 'selfs' : this.navList[this.onIndex].label
		  }
		  this.$store.commit('setStore',{
				name:'mainLable', 
				data:label
		    })
		  this.$router.replace({name:page.name,query:{label:label}})
        }
      }
    },
  },
  async created(){
	  this.isRule = true
	  this.$store.commit('setStore',{
	  	name:'mainLable', 
	  	data:""
	  })
	  this.onIndex = 0
	  
    var user = this.$utils.storeGet('user'); // 获取缓存中的用户信息
    var proId = this.$utils.storeGet('proId'); // 获取缓存中的项目ID
   // 获取缓存导航位置
    this.$store.commit('setStore', { name:'mainNavIndex', data:this.$utils.storeGet('mainNavIndex')||0 });
    this.$store.commit('setStore', { name:'subNavIndex', data:this.$utils.storeGet('subNavIndex')||0 })

    if(proId){
      this.$store.commit('setStore', {
        name: 'proId', 
        data: proId
      })
    }
    
    if(user){
      this.$store.commit('setStore', {
        name:'user', 
        data:user
      })
    }else if(this.$route.name!='Login'){
      this.$router.replace({path: '/Login'});
    }
  },
  methods: {
    async getRules(){
      var rules = await this.$utils.api.get({
        url:'/user/rules',
        data:{},
		isRule:this.isRule // 首次加载
      })
      this.navList = rules.rules;
      this.onIndex = -1;
	   this.isRule = false
      setTimeout(()=>{	
        this.onIndex = 0;
		this.$store.commit('setStore',{
			name:'mainLable', 
			data:this.navList[this.onIndex].label
		})
      })
    },
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      } else {
        return document.body;
      }
    },
    getTypeSetup(arr, name){
      var newList = [];
      var task = arr.find(item=>item.name=='api/task'),// 1
        contract = arr.find(item=>item.name=='api/contract'),
        sale = arr.find(item=>item.name=='api/sale'),
        demand = arr.find(item=>item.name=='api/demand'),// 1
        demandDate = arr.find(item=>item.name=='api/demand_date'),// 1
        demandRecord = arr.find(item=>item.name=='api/demand_record'),
        modular = arr.find(item=>item.name=='api/modular'),
        thirdInfo = arr.find(item=>item.name=='api/third_info'),
        cases = arr.find(item=>item.name=='api/cases'),// 1
        bug = arr.find(item=>item.name=='api/bug'),// 1
        team = arr.find(item=>item.name=='api/team'),
        project = arr.find(item=>item.name=='api/project');
      if(task){
        newList.push({
          title:'任务',
          name:'TaskProject',
          hasPro:modular,
          child:task.child
        })
      }
      if(contract&&name!='解决方案经理'){
        newList.push({
          title:'合同情况',
          name:name=='项目经理'?'ContractSituation':'DevelopmentContractSituation',
          child:contract.child
        })
      }
      if(sale){
        newList.push({
          title:'销售机会',
          name:'SolutionSales',
          child:sale.child
        })
      }
      if(contract&&name=='解决方案经理'){
        if(contract.child.find(ite=>ite.name=='api/contract/p_index')){
          newList.push({
            title:'客户管理',
            name:'SolutionCustomer',
            child:contract.child
          })
        }
        if(contract.child.find(ite=>ite.name=='api/contract/collection_index')){
          newList.push({
            title:'收款记录',
            name:'SolutionCollection',
            child:contract.child
          })
        }
      }
      if(demand||(demandDate&&name!='开发')){
        let arr = [];
        if(demand){
          demand = {...demand}
          arr = arr.concat(demand.child);
        }
        if(demandDate){
          demandDate = {...demandDate}
          arr = arr.concat(demandDate.child);
        }
        if(name=='测试'&&cases){
          let dt = cases.child.find(item=>item.name=='api/cases/add')
          if(dt)arr.push(dt)
        }
        if(name=='项目经理'&&task){
          let dt = task.child.find(item=>item.name=='api/task/create_task')
          if(dt)arr.push(dt)
        }
		if(name != '解决方案经理'){ // 解决方案经理不显示需求
			newList.push({
			  title:'需求',
			  name:'Product',
			  hasPro:modular,
			  child: arr
			})
		}
      }
      if(demandRecord){
        newList.push({
          title:'需求记录单',
          name:'ProductRecord',
		  hasPro:modular,
          child:demandRecord.child
        })
      }
      if(thirdInfo){
        newList.push({
          title:'第三方账号',
          name:'Third',
          child:thirdInfo.child
        })
      }
      if(cases){
        if(name=='测试'){
          cases.child.push({
            title:"转BUG",
            name:"api/cases/bug",
          })
        }
        newList.push({
          title:'测试用例',
          name:'TheTestCase',
          hasPro:modular,
          child:cases.child
        })
      }
      if(bug){
        newList.push({
          title:'BUG',
          name:'DevelopmentBug',
          hasPro:modular,
          child:bug.child
        })
      }

      if(team){
        newList.push({
          title:'团队',
          name:'TaskProjectMember',
          child:team.child
        })
      }
      if(project){
        newList.push({
          title:'项目管理',
          name:'TaskProjectManagement',
          child:project.child
        })
      }
      return newList;
    }
  },
  destroyed() {
  	console.log("销毁")
  }
};



</script>


<style lang="scss">
  .bbbd{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:#f0f3fa;
    user-select:none;
    overflow: hidden;
    min-width:1200px;
    min-height:600px;
    &> .g-header{
      position: absolute;
      top:0;
      left:0;
      right:0;
      height:80px;
      background:#014098;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:0 30px;
      z-index: 2;
    }
    &> .g-body{
      // background: rgba(255,0,0,.3);
      position: absolute;
      top:80px;
      left:0;
      right:0;
      bottom:0;
      z-index: 0;
      transition:all .36s linear;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px; // 控制滚动条
      }
      // &::-webkit-scrollbar-track{
      //   background-color:#f0f3fa; // 滚动条颜色
      // }
      &::-webkit-scrollbar-thumb{
        border: 2px solid #f0f3fa;
        border-top-width: 10px;
        border-bottom-width: 10px;
        background-color: #014098; // 滚动条颜色
      }
    }
    &> .g-tabs{
      position: absolute;
      top:80px;
      left:0;
      right:0;
      height:70px;
      background:#fff;
      padding-left:20px;
      box-shadow: 0 1px 10px rgba(1,64,152,.3);
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      z-index: 1;
      &~.g-body{
        top:150px;
      }
      &~.g-sider,&~.g-msg{
        top:170px;
      }
    }
    &> .g-sider{
      position: absolute;
      top:100px;
      left:20px;
      bottom:20px;
      width:250px;
      padding:10px 0;
      // background: #fff;
      // border-radius: 5px;
      // box-shadow: 0 1px 10px rgba(1,64,152,.1);
      overflow: hidden;
      &~.g-body{
        left:270px;
      }
    }
    &> .g-msg{
      position: absolute;
      top:100px;
      right:0;
      bottom:20px;
      margin:auto;
      transition:all .36s linear;
      z-index: 5;
      width:0px;
      .onoff{
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        margin:auto;
        width:20px;
        height:50px;
        border-radius: 10px 0 0 10px;
        background: #fff;
        box-shadow: 0 2px 5px rgba(0,0,0,.08);
        &:after,&:before{
          content:'';
          display: block;
          width:16px;
          position: absolute;
          top:0;
          left:0;
          right:0;
          bottom:0;
          margin:auto;
          transition: transform .36s;
        }
        &:after{
          height:16px;
          border: solid #999;
          border-width: 2px 0 2px;
          transform: rotate(0deg);
        }
        &:before{
          height:2px;
          border-top:2px solid #999;
          transform: rotate(0deg);
        }
        &.on{
          &:after{
            height:2px;
            border-width: 1px 0 1px;
            transform: rotate(45deg);
          }
          &:before{
            transform: rotate(-45deg);
          }
        }
      }
      &.open{
        width:350px;
        &~.g-body{
          right:350px;
        }
      }
    }
  }
  .ant-dropdown-content{
    user-select: none;
  }

  .fra{
    display: flex;
    justify-content: flex-end;
    .ant-btn{
      margin-left:20px;
    }
  }
  .ant-pagination{
    .ant-pagination-item-active{
      color: #fff;
      background: #014098;
      border-color: #014098;
      a{
        color: #fff;
        &:hover{
          color: #fff;        
        }
      }
      &:hover{
        background: #0348a8;
        border-color: #0348a8;
      }
    }
	.ant-pagination-options{
		// 隐藏分页的下拉选项
		display:none;
	}
  }
  .ant-btn{
    &.clo1{
      background: #00b7ee;
      color: #fff;
      border-color: #00b7ee;
      &:hover{
        background: #07c2fa;
        border-color: #07c2fa;
        color: #fff;
      }
    }
    &.clo2{
      background: #f19149;
      color: #fff;
      border-color: #f19149;
      &:hover{
        background: #ff9c52;
        border-color: #ff9c52;
        color: #fff;
      }
    }
    &.clo3{
      background: #009e96;
      color: #fff;
      border-color: #009e96;
      &:hover{
        background: #00aba2;
        border-color: #00aba2;
        color: #fff;
      }
    }
    &.clo4{
      background: #32b16c;
      color: #fff;
      border-color: #32b16c;
      &:hover{
        background: #3bc379;
        border-color: #3bc379;
        color: #fff;
      }
    }
    &.clo5{
      background: #014098;
      color: #fff;
      border-color: #014098;
      &:hover{
        background: #0348a8;
        border-color: #0348a8;
        color: #fff;
      }
    }
    &.clo6{
      background: #8f929a;
      color: #fff;
      border-color: #8f929a;
      &:hover{
        background: #a0a1a5;
        border-color: #a0a1a5;
        color: #fff;
      }
    }
  }

  .need-box{
  	.ant-form-item-label{
  		// form 表单itemlable设置间距
  		padding-right: 20px !important;
  	}
  }
  .img-scroll{
  	.ant-image{
  		width: 90px !important;
  		height: 90px !important;
  		margin-right: 5px;
  		.ant-image-img{
  			width: 90px !important; 
  			height: 90px !important;
  		}
  	}
  }
  .overimg-box{
  	.ant-image{
  		width: 65px !important;
  		height: 65px !important;
  		margin-right: 5px;
  		.ant-image-img{
  			width: 65px !important; 
  			height: 65px !important;
  		}
  	}
  }
  .overimg-box2{
  	.ant-image{
  		width: 90px !important;
  		height: 90px !important;
  		margin-right: 5px;
  		.ant-image-img{
  			width: 90px !important; 
  			height: 90px !important;
  		}
  	}
  }
  .checkImgs{
  	.ant-image{
  		width: 90px !important;
  		height: 90px !important;
  		.ant-image-img{
  			width: 90px !important; 
  			height: 90px !important;
  		}
  	}
  }
  .um-scroll{
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px; // 控制滚动条
    }
    &::-webkit-scrollbar-track{
      background-color:#fff; // 滚动条颜色
    }
    &::-webkit-scrollbar-thumb{
      border: 2px solid #fff;
      border-top-width: 10px;
      border-bottom-width: 10px;
      background-color: #014098; // 滚动条颜色
    }
    &:hover::-webkit-scrollbar {
      width: 6px;
    }
  }

  .noHas{
    text-align: center;
    color:#999;
  }
  .ant-modal-wrap.sider{
    left:330px;
  }
	.overtext_hide{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

  .treeList{
    .ant-tree-switcher{
      display: flex;
      align-items: center;
      justify-content: center;
      width:38px;
      font-size:30px;
    }
    .ant-tree-treenode{
      display: flex;
      width:100%;
    }
    .ant-tree-node-content-wrapper{
      flex:1;
      border-radius: 5px 0 0 5px;
      margin-left:-10px;
      &:hover{
        background:transparent;
        color:#07c2fa;
      }
      // &:after{
      //   content:'';
      //   display: block;
      //   position: absolute;
      //   top:0;
      //   right:-30px;
      //   bottom:0;
      //   margin:auto;
      //   width:12px;
      //   height:12px;
      //   border-radius: 2px;
      //   border-top: 2px solid #fff;
      //   border-right: 2px solid #fff;
      //   transform: rotate(45deg);
      //   transition: all .36s;
      // }
      &.ant-tree-node-selected{
        margin-left:0px;
        color:#fff;
        background:#07c2fa;
        position: relative;
        &:after{
          right:20px;
        }
      }
    }
    .ant-tree-title{
      .item{
        display: block;
        width:160px;
        padding: 10px;
        font-size:14px;
        line-height:20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
	.swiper-box .swiper .ant-image{
		height:800px;
		.ant-image-img{
			// 轮播图图片自适应宽高
			height: 95%;
			object-fit: contain;
		}
	}
	.image-w-h{
		height:60px;
		overflow: hidden;
	}
	.ellipsis-row{
		// 单行 点点点
	    white-space:nowrap;
		overflow: hidden;
		text-overflow: ellipsis; 
	}
	.ellipsis-rows{
		// 多行点点点
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}
	.ellipsis-rows2{
		// 多行点点点
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
	.ellipsis-rows5{
		// 多行点点点
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 5;
		overflow: hidden;
	}
	.overlayClassName{
		// tooltip 文字提示样式
		max-width: 800px;
	}
	.ptxtp{
		margin: 0 !important;
	}
</style>
