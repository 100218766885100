<template>
  <div>
    <div class="msg">
      <GModule class="message" v-if="isHistory">
        <GTitle name="历史记录"></GTitle>
        <div class="history-box um-scroll" v-if="isHistory.list.length">
          <div class="item" v-for="(item,index) in isHistory.list" :key="index">
            <h3>{{index + 1}}.<span>{{item.createtime_text}}</span>{{'　'+item.name}} <span class="read" v-if="item.opt=='fail'&&authority.reply" @click="reply=1">回复</span></h3>
            <p v-if="item.msg" v-text="item.msg"></p>
          </div>
          <!-- <div class="item" v-for="(item,index) in history" :key="index">
            <h3>{{isHistory.list.length + index}}.<span>{{item.createtime_text}}</span>{{'　'+item.name}}</h3>
            <p v-if="item.msg" v-text="item.msg"></p>
          </div> -->
        </div>
        <a-modal v-model:visible="reply"
          title="回复信息"
          @ok="submitReply">
          <a-form :model="replyText">
            <a-form-item label="回复内容">
              <a-input v-model:value="replyText" placeholder="请输入回复内容" />
            </a-form-item>
          </a-form>          
        </a-modal>
      </GModule>
      <template v-else>
        <GModule class="message" v-if="isUrls">
          <GTitle name="相关地址链接"></GTitle>
  				<div class="aboutlink-box um-scroll" v-if="urlArr.length">
  					<div class="link-li" v-for="(item,index) in urlArr" :key="index">
  						<div class="tit"><span class="name">{{item.name}}</span><span class="btn clo" v-if="urlsData.edit" @click="linkForm('edit',item)">编辑</span><span class="btn"><a-popconfirm v-if="urlsData.del" title="是否确认删除?" @confirm="linkForm('del',item.id)">删除</a-popconfirm></span></div>
  						<a target="_blank" :href="item.url">{{item.url}}</a>
  					</div>
  				</div>
          <div class="noHas" v-else>暂无数据</div>
          <a-button class="addUrls clo4" v-if="urlsData.add" @click="linkForm('add')">添加</a-button>

          <a-modal v-model:visible="linkObj"
            :title="linkObj||''"
            @ok="submitLink">

            <a-form :model="formLink">
              <a-form-item label="链接名称">
                <a-input v-model:value="formLink.name" placeholder="请输入链接名称" />
              </a-form-item>
              <a-form-item label="链接地址">
                <a-input v-model:value="formLink.url" placeholder="请输入链接地址" />
              </a-form-item>
            </a-form>          
          </a-modal>
        </GModule>
        <GModule class="message" v-if="isMsgs">
          <GTitle name="消息通知"></GTitle>
  				<div class="aboutlink-box um-scroll" v-if="messageArr.length">
  					<div class="item" v-for="(item,index) in messageArr" :key="index" @click="openMes(item.id)">{{index + 1}}.{{item.content_data}}</div>
  				</div>
          <div class="noHas" v-else>暂无数据</div>
  				<MsgModal v-if="isMsg" :msgObj="msgObj" @closeModal='closeModal'></MsgModal>
        </GModule>
      </template>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Message',
  data(){
    return {
      reply:null,
  		messageArr:[], // 消息列表
  		isMsg:false ,// 是否弹出消息提示框
  		msgObj:{} ,// 消息
  		urlArr:[], // 相关地址链接
      linkObj:null,
      formLink:{},
      replyText:'',
      history:[]
    }
  },
  props:['isMsgs','isUrls','isHistory'],// isMsgs消息，isUrls链接，isHistory历史，历史覆盖其他
  computed:{
    authority(){
      var data = {};
      this.$store.state.params.forEach(item=>{
        let name = item.name.split('/');
        if(name[1]=='demand'){
          data[name[2]] = true;
        }else if(name[1]=='demand_date'){
          data[name[2]+'Date'] = true;
        }else if(name[1]=='cases'){
          data[name[2]+'Cases'] = true;
        }else if(name[1]=='task'){
          data['createTask'] = true;
        }
      })
      return data
    },
  	proId(){
  		return this.$store.state.proId  
  	},
    watchs(){
      return {
        proId:this.proId,
        isMsgs:this.isMsgs,
        isUrls:this.isUrls,
      }
    },
    urlsData(){
      var data = {};
      if(this.isUrls&&this.isUrls.child){
        for(var i in this.isUrls.child){
          if(this.isUrls.child[i].title=='添加')data.add=!0;
          if(this.isUrls.child[i].title=='编辑')data.edit=!0;
          if(this.isUrls.child[i].title=='删除')data.del=!0;
        }
      }
      return data
    },
    user() {
      return this.$store.state.user
    },
  },
  watch:{
    watchs(){
      if(this.user){
        this.getList();
      }
      // console.log('isUrls',this.isUrls)
    },
  },
  created() {
  	// 获取消息列表
    this.getList();
  },
  methods:{
    async submitReply(){
      if(!this.replyText){
        return this.$utils.msgErr('输入回复内容');
      }
      var res = await this.$utils.api.post({
        url:'/'+this.isHistory.type+'/reply',
        data:{ids:this.isHistory.id,msg:this.replyText},
        result:1
      });
      if(res.code==1){
        this.$utils.msgSuc('回复成功！');
          var time = new Date();
          var year = time.getFullYear();
          var month = (time.getMonth() + 1) > 9 && (time.getMonth() + 1) || ('0' + (time.getMonth() + 1))
          var date = time.getDate() > 9 && time.getDate() || ('0' + time.getDate())
          var hour = time.getHours() > 9 && time.getHours() || ('0' + time.getHours())
          var minute = time.getMinutes() > 9 && time.getMinutes() || ('0' + time.getMinutes())
          var second = time.getSeconds() > 9 && time.getSeconds() || ('0' + time.getSeconds())
        this.history.push({
          createtime_text:year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
          name:'由'+this.user.nickname+'回复审核人',
          msg:this.replyText+'',
        })
        this.reply = 0;
      }

//isHistory
    },
    async submitLink(){
      var url = this.linkObj=='新增相关地址链接'?'/urls/add':'/urls/edit',
        msg = this.linkObj=='新增相关地址链接'?'新增':'编辑';
      // console.log(this.$utils.msgErr())
      if(!this.formLink.name){
        this.$utils.msgErr('输入链接名称');
        return
      }
      if(!this.formLink.url){
        this.$utils.msgErr('输入链接地址');
        return
      }
      var subdata = {row:{...this.formLink}};
      if(this.formLink.id)subdata.ids = this.formLink.id;
      var suc = await this.$utils.api.post({
        url:url,
        data:subdata,
        result:1
      });
      if(suc.code==1){
        this.$utils.msgSuc(msg+'成功！');
        this.linkObj=null;
        this.getList();
      }
    },
    linkForm(type,obj){
      var that = this;
      if(type=='add'){
        this.linkObj = '新增相关地址链接';
        this.formLink = {name:'',url:'',project_id:this.proId};
      }else if(type=='edit'){
        this.linkObj = '编辑相关地址链接';
        this.formLink = {...obj,project_id:this.proId};
      }else if(type=='del'){
        this.delLink(obj);
      }
    },
    async delLink(id){
      var suc = await this.$utils.api.post({
        url:'/urls/del',
        data:{ids:id},
        result:1
      });
      if(suc.code==1){
        this.$utils.msgSuc('删除成功！');
        this.linkObj=null;
        this.getList();
      }
    },
    async getList(){
      if(!this.proId)return;
      this.messageArr = [];
      this.urlArr = [];
      if(this.isMsgs){
      	let res = await this.$utils.api.post({
      		url:'/msg/index',
    		data:{
    			project_id:this.proId
    		},
    		result:1
      	})
      	if(res.code == 1){
      		this.messageArr = res.data.rows
      	}
      }
      if(this.isUrls){
      	// 获取相关地址链接
      	let ress = await this.$utils.api.post({
      		url:'/urls/index',
      		data:{
      			project_id:this.proId
      		},
      		result:1
      	})
      	if(ress.code == 1){
      		this.urlArr = ress.data.rows
      	}
      }
    },
  	async openMes(id){
  		// 获取消息详情
  		var res = await this.$utils.api.post({
  			url:'/msg/row',
  			data:{
  				ids:id
  			},
  			result:1
  		})
  		if(res.code == 1){
  			this.isMsg = true
  			this.msgObj = res.data.content_data
  		}
  	},
  	closeModal(val){
  		this.isMsg = val
  	}
  }
};
</script>

<style scoped lang="scss">
.msg{
  width:330px;
  height: 100%;
  overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  .noHas{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    width:290px;
  }
  .addUrls{
    width:290px;
  }
	.message{
		flex:1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-right: 0;
		&:not(:first-child){
			margin-top:20px;
		}
		.aboutlink-box{
			display: block;
			flex:1;
			.link-li{
				padding: 5px 0;
			}
      .tit{
        display: flex;
        .name{
          flex:1;
        }
      }
      .btn{
        margin-right:10px;
        color:#999;
        &.clo{
          color:#00b7ee;
        }
      }
			.item{
				width: 290px;
				line-height: 30px;
				/* 文字超出点点点 */
				word-break: break-all;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
			}
		} 
	}
  .history-box{
    display: block;
    flex:1;
    .item{
      width: 290px;
      line-height: 20px;
      padding:5px 0;
      h3{
        font-weight: normal;
        font-size:14px;
        span{
          font-size:12px;
        }
      }
      p{
        font-size:12px;
        color:#999;
      }
      &.err{
        h3{
          color:#f00;
        }
        p{
          color:#333;
        }
      }
      .read{
          color:#07c2fa;
      }
    }
  }
}
</style>
