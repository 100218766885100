<template>
	<!-- 展示图片列表 -->
    <div class="swiper-box">
		<div class="close" @click="closeFun()"></div>
		<div class="count">{{count}}/{{imgArr.length}}</div>
		<div class="closebtn" @click="closeFun()">关闭</div>
		<div class="swiper">
			<a-carousel arrows :dots="false" :afterChange="afterChange">
			  <template #prevArrow>
			    <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
			      <left-circle-outlined />
			    </div>
			  </template>
			  <template #nextArrow>
			    <div class="custom-slick-arrow" style="right: 10px">
			      <right-circle-outlined />
			    </div>
			  </template>
			  <a-image :preview="false" v-for="(item,index) in imgArr" :key="index" :src="item" />
			</a-carousel>
		</div>
    </div>
</template>
<script>
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent ,ref} from 'vue';
import { useStore } from 'vuex'
export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  setup() {
	// 获取vuex 
	const store = useStore()
	// 获取图片列表
	const imgArr = store.state.swiperArr 
  	const closeFun = ()=>{
		//重置图片列表数据
		store.commit('setSwiperArr',[])
		// 关闭图片列表
		store.commit('setSwiper',0)
	}
	//计数
	const count = ref(1)
	const afterChange = (val)=>{
		count.value = val+1
	}
	return{
		store,
		closeFun,
		afterChange,
		count,
		imgArr
	}
  }
});
</script>
<style scoped>
.swiper-box{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.7);
}
.swiper-box .swiper{
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 999;
	transform: translate(-50%,-50%);
	width: 1200px;
	height: 800px;
}
.swiper-box .close{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 800px;
  line-height: 800px;
}
.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #fff;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
.count{
	position: absolute;
	top: 20px;
	left: 20px;
	font-size: 30px;
	color: #fff;
}
.closebtn{
	position: absolute;
	top: 20px;
	right: 20px;
	font-size:24px;
	color: #fff;
}
</style>