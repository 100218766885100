<template>
  <div class="g-page">
    <!-- <GTitle name="标题内容是" tip="提示文字是"></GTitle>
    <GModule class="f-mb20">
      <a-tabs v-model:activeKey="activeKey" type="editable-card" @edit="onEdit">
        <a-tab-pane v-for="pane in panes" :key="pane.key" :tab="pane.title" :closable="pane.closable">
          {{ pane.content }}
        </a-tab-pane>
      </a-tabs>


      <a-space>
        <a-select ref="select" v-model:value="value1" style="width: 120px">
          <a-select-option value="jack">Jack</a-select-option>
          <a-select-option value="lucy">Lucy</a-select-option>
          <a-select-option value="disabled" disabled>Disabled</a-select-option>
          <a-select-option value="Yiminghe">yiminghe</a-select-option>
        </a-select>
        <a-select ref="select" v-model:value="valuem" style="width: 220px" mode="multiple" :options="arrM.map((it)=>({value:it.name,name:it.name}))" option-label-prop="name">
          <template #option="{ name }">{{ name }}</template>
        <a-select-option value="jack">Jack</a-select-option>
          <a-select-option value="lucy">Lucy</a-select-option>
          <a-select-option value="disabled" disabled>Disabled</a-select-option>
          <a-select-option value="Yiminghe">yiminghe</a-select-option>
        </a-select>
        <a-range-picker v-model:value="value">
          <span>
            {{ value1 ? value1.toString() : '请选择' }}
          </span>
        </a-range-picker>

        <a-range-picker v-model:value="value1" >
          <span>????</span>
        </a-range-picker>
      </a-space>
    </GModule>
    <GModule class="f-mb20">
      <div class="fra hm-hd">
        <a-button class="clo1">Button</a-button>
        <a-button class="clo2">Button</a-button>
        <a-button class="clo3">Button</a-button>
        <a-button class="clo4">Button</a-button>
        <a-button class="clo5">Button</a-button>
        <a-button class="clo6">Button</a-button>
      </div>
      <a-table class="ttable f-mb20" :row-selection="rowSelection" sticky :columns="columns" :data-source="data" :scroll="{ x: 1500 }" bordered  >
        <template #bodyCell="{ column }">
          <template v-if="column.key === 'operation'"><a>action</a></template>
        </template>
      </a-table>
      <a-pagination class="tpage" v-model:current="current1" show-quick-jumper :total="500" @change="onChange" />
    </GModule>
    <GModule>
      <GTitle name="表单名称"></GTitle>
      <a-table class="ttable2 f-mb20" sticky :columns="columns" :data-source="data" :scroll="{ x: 1500 }"  >
        <template #bodyCell="{ column }">
          <template v-if="column.key === 'operation'"><a>action</a></template>
        </template>
      </a-table>
      <a-pagination class="tpage" v-model:current="current1" show-quick-jumper :total="500" @change="onChange" />
    </GModule> -->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
      panes:[
        {
          title: 'Tab 1',
          content: 'Content of Tab 1',
          key: '1',
        },
        {
          title: 'Tab 2',
          content: 'Content of Tab 2',
          key: '2',
        },
        {
          title: 'Tab 3',
          content: 'Content of Tab 3',
          key: '3',
          closable: false,
        },
      ],
      arrM:[{
        name:'12354'
      }, {
        name:'dasd5'
      }, {
        name:'gdds2'
      }],
      value: '',
      value1: '',
      data: [],
      valuem:[],
      rowSelection:{
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
          disabled: record.name === 'Disabled User',
          // Column configuration not to be checked
          name: record.name,
        }),
      },
      fixedTop: 0,
      columns: [
        {
          title: 'Full Name',
          width: 100,
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
        }, {
          title: 'Age',
          width: 100,
          dataIndex: 'age',
          key: 'age'
        }, {
          title: 'Column 1',
          dataIndex: 'address',
          key: '1',
          width: 150,
        }, {
          title: 'Column 2',
          dataIndex: 'address',
          key: '2',
          width: 150,
        }, {
          title: 'Column 3',
          dataIndex: 'address',
          key: '3',
          width: 150,
        }, {
          title: 'Column 4',
          dataIndex: 'address',
          key: '4',
          width: 150,
        }, {
          title: 'Column 5',
          dataIndex: 'address',
          key: '5',
          width: 150,
        }, {
          title: 'Column 6',
          dataIndex: 'address',
          key: '6',
          width: 150,
        }, {
          title: 'Column 7',
          dataIndex: 'address',
          key: '7',
          width: 150,
        }, {
          title: 'Column 8',
          dataIndex: 'address',
          key: '8',
        }, {
          title: 'Action',
          key: 'operation',
          fixed: 'right',
          width: 100,
        }
      ]
    }
  },
  
};
</script>

<style scoped lang="scss">
.g-page{
  padding:20px;
}
.f-mb20{
  margin-bottom: 20px;
}
.hm-hd{
}
.ttable{
  margin-top:20px;
}
.tpage{
  text-align: center;
}
.ttable2{
  .ant-table-thead > tr > th{
    background: #fff;
  }
}

</style>

