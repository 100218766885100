<template>
	<template v-if="isEdg">
		<canvas id="canvas" class="backgroundCanvas"></canvas>
	</template>
	<div class="backgroundCanvas2" v-else></div>
  
</template>

<script>
export default {
  name: 'Background',
  data(){
    return {
		isEdg:false
    }
  },
  props:{
    name:'',
    tip:'',
    type:0
  },
  created() {
  	var userAgent = navigator.userAgent;
  	//判断是否Edge浏览器
  	if (userAgent.indexOf("Edg") > -1) {
  		// Microsoft Edge
  		this.isEdg = false
  	}else{
  		this.isEdg = true
  	}
  	console.log("isEdg",this.isEdg)
  },
  mounted(){
	  if(!this.isEdg){
		  return
	  }
    var c = document.querySelector('#canvas');
    console.log(c)
    var w = c.width = window.innerWidth,
    h = c.height = window.innerHeight,
    ctx = c.getContext('2d'),
    opts = {
      len: w/35,
      count: w/35*3,
      baseTime: 10,
      addedTime: 10,
      dieChance: .05,
      spawnChance: 1,
      sparkChance: .001, // 杂点
      sparkDist: 1,
      sparkSize: 1,
      color: 'hsl(hue,100%,light%)',
      baseLight: 50,
      addedLight: 0,
      shadowToTimePropMult: 6,
      baseLightInputMultiplier: .01,
      addedLightInputMultiplier: .02,
      cx: w / 1.3,
      cy: h / 1.5,
      repaintAlpha: .1,
      hueChange: .01
    },
    tick = 0,
    lines = [],
    dieX = w / 2 / opts.len,
    dieY = h / 2 / opts.len,
    baseRad = Math.PI * 2 / 6;
    var sColor = 'rgba(0,64,152,1)';
    var cColor = 'rgba(0,9,22,1)';
    var eColor = 'rgba(0,0,0,1)';
    var grads = ctx.createLinearGradient(0,0,w/1.5,h/1.5);
    grads.addColorStop(0,sColor);//定义渐变色颜色
    grads.addColorStop(0.8,cColor);
    grads.addColorStop(1,eColor);
    ctx.fillStyle = grads;
    ctx.fillRect(0, 0, w, h);
    function loop() {
      window.requestAnimationFrame(loop);
      ++tick;
      ctx.globalCompositeOperation = 'source-over';
      ctx.shadowBlur = 0;
      // ctx.fillStyle = 'rgba(0,64,152,alp)'.replace('alp', opts.repaintAlpha);
      var grad=ctx.createLinearGradient(0,0,w/1.5,h/1.5); //创建一个渐变色线性对象
      var sColor = 'rgba(0,64,152,1)';
      var cColor = 'rgba(0,9,22,alp)'.replace('alp', opts.repaintAlpha);
      var eColor = 'rgba(0,0,0,alp)'.replace('alp', opts.repaintAlpha);
      grad.addColorStop(0,sColor);//定义渐变色颜色
      grad.addColorStop(0.8,cColor);
      grad.addColorStop(1,eColor);
      ctx.fillStyle=grad;
      ctx.fillRect(0, 0, w, h);
      ctx.globalCompositeOperation = 'lighter';
      if (lines.length < opts.count && Math.random() < opts.spawnChance) lines.push(new Line);
      lines.map(function(line) {
        line.step()
      })
    }
    function Line() {
      this.reset()
    }
    Line.prototype.reset = function() {
      this.x = 0;
      this.y = 0;
      this.addedX = 0;
      this.addedY = 0;
      this.rad = 0;
      this.lightInputMultiplier = opts.baseLightInputMultiplier + opts.addedLightInputMultiplier * Math.random();
      this.color = opts.color.replace('hue', '230');// tick * opts.hueChange);
      this.cumulativeTime = 0;
      this.beginPhase()
    }
    Line.prototype.beginPhase = function() {
      this.x += this.addedX;
      this.y += this.addedY;
      this.time = 0;
      this.targetTime = (opts.baseTime + opts.addedTime * Math.random()) | 0;
      this.rad += baseRad * (Math.random() < .5 ? 1 : -1);
      this.addedX = Math.cos(this.rad);
      this.addedY = Math.sin(this.rad);
      if (Math.random() < opts.dieChance || this.x > dieX || this.x < -dieX || this.y > dieY || this.y < -dieY) this.reset()
    }
    Line.prototype.step = function() {++this.time; ++this.cumulativeTime;
      if (this.time >= this.targetTime) this.beginPhase();
      var prop = this.time / this.targetTime,
      wave = Math.sin(prop * Math.PI / 2),
      x = this.addedX * wave,
      y = this.addedY * wave;
      ctx.shadowBlur = prop * opts.shadowToTimePropMult;
      ctx.fillStyle = ctx.shadowColor = this.color.replace('light', opts.baseLight + opts.addedLight * Math.sin(this.cumulativeTime * this.lightInputMultiplier));
      ctx.fillRect(opts.cx + (this.x + x) * opts.len, opts.cy + (this.y + y) * opts.len, 2, 2);
      if (Math.random() < opts.sparkChance) ctx.fillRect(opts.cx + (this.x + x) * opts.len + Math.random() * opts.sparkDist * (Math.random() < .5 ? 1 : -1) - opts.sparkSize / 2, opts.cy + (this.y + y) * opts.len + Math.random() * opts.sparkDist * (Math.random() < .5 ? 1 : -1) - opts.sparkSize / 2, opts.sparkSize, opts.sparkSize)
    }
    loop();
    window.addEventListener('resize',
    function() {
      w = c.width = window.innerWidth;
      h = c.height = window.innerHeight;
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, w, h);
      opts.cx = w / 2;
      opts.cy = h / 2;
      dieX = w / 2 / opts.len;
      dieY = h / 2 / opts.len
    });

  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.backgroundCanvas{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.backgroundCanvas2{
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background: linear-gradient(rgba(0,64,152,1),rgba(0,9,22,0.8));
}
</style>
