<template>
	<!-- 添加第三方 -->
	<div class="modal-box">
		<div class="third-box">
			<div class="first one">
				<p>添加第三方</p>
				<close-outlined @click="close()"/>
			</div>
			<div class="first1">
				<p>第三方名称</p>
				<a-input placeholder="请输入第三方名称" class="input1"/>
			</div>
			<div class="first1">
				<p>注册地址</p>
				<a-input placeholder="请输入注册地址" class="input1"/>
			</div>
			<div class="btn">
				<a-button class="clo1">提交</a-button>
				<a-button class="clo6" @click="close()">取消</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			close(){
				this.$store.commit('setIsThirdAdd',0)
			}
		}
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 500px;
			height: 300px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.first1{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 30px 20px 0 20px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
			.input1{
				width: 350px;
			}
			.btn{
				display: flex;
				justify-content: center;
				margin-top: 40px;
				.clo1{
					margin-right: 30px;
				}
			}
		}
	}
</style>