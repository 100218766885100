<template>
	<!-- 消息列表信息弹窗 -->
	<div>
	    <a-modal 
			class="self-modal-info" 
			visible
			title="消息通知"
			:closable="false"
			:centered="true"
			cancelText=" "
			@cancel="handleCancel"
			@ok="handleOk">
	      <p>{{msgObj}}</p>
	    </a-modal>
	</div>
</template>
<script>
import { defineComponent, h } from 'vue';
export default defineComponent({
  props:{
	 msgObj:{
		 type:String
	 }
  }	,
  setup(props,{emit}) {
	const handleOk = () => {
	    emit("closeModal",false);
	};
	const handleCancel = () =>{
		emit("closeModal",false);
	};
	return {
	  handleOk,
	  handleCancel
	};
  },

});
</script>
<style lang="scss">
.self-modal-info{
	width: 750px !important;
	max-height: 650px !important;
	.ant-modal-body{
		max-height: 500px !important;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 2px; // 控制滚动条
		}
		&::-webkit-scrollbar-thumb{
			background-color: #bfbfbf; // 滚动条颜色
		}
	}
} 
</style>