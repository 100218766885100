import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import 'dayjs/locale/zh-cn';
import App from './App.vue';
import router from './router';
import store from './store';

import utils from './components/common.js';
import * as Icons from '@ant-design/icons-vue' // 导入安装好的图标


const app = createApp(App);


app.use(store).use(Antd);
app.config.globalProperties.$utils = utils;

import GTitle from './components/GTitle.vue';
import GModule from './components/GModule.vue';
import MsgModal from './components/MsgModal.vue';
app.component("GTitle", GTitle);
app.component("GModule", GModule);
app.component("MsgModal", MsgModal);

// 全局注册图标
const icons = Icons;
for(const i in icons){
	app.component(i,icons[i])
}


app.use(router).mount('#app');
