<template>
  <div class="g-module">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GTitle',
  data(){
    return {
    }
  },
  props:{
    name:'',
    tip:'',
    type:0
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.g-module{
  padding:15px 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 10px rgba(1,64,152,.1);
}
</style>
