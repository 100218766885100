<template>
	<!-- 图片下载列表 -->
	<div class="modal-box checkImgs">
		<div class="modal-cancel" @click="closeFun()"></div>
		<div class="modal">
			<div class="imgs-box">
				<div v-for="(itm,ind) in imgOtherArr" :key="ind">
					<div class="imgs" @click="checkClick(ind)">
						<a-image :preview="false" :width="90" :src="itm.url" />
						<div class="check"><check-circle-filled :style='"font-size: 23px;color: "+ (itm.isCheck ? "#1890ff;" : "#a0a1a5;") '/></div>
					</div>
				</div>
			</div>
			<div class="btn">
				<a-button class="clo1" @click="downMore()">下载</a-button>
				<a-button class="clo6" @click="closeFun()">取消</a-button>
			</div>
		</div>	
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		computed:{
			imgOtherArr(){
				return this.$store.state.imgOtherArr
			},
		},
		methods:{
			closeFun(){
				this.$store.commit('setImgOther',0)
			},
			checkClick(index){
				this.imgOtherArr[index].isCheck = ! this.imgOtherArr[index].isCheck
				this.$store.commit('setImgOtherArr',this.imgOtherArr)
			},
			downMore(){
				// 开始下载
				for(let i in this.imgOtherArr){
					if(this.imgOtherArr[i].isCheck){
						let name = new Date().getTime();
						this.downloadIamge(this.imgOtherArr[i].url, name);
					}
				}
			},
			downloadIamge(imgsrc, name) {
			  //下载图片地址和图片名
			  var image = new Image();
			  // 解决跨域 Canvas 污染问题
			  image.setAttribute("crossOrigin", "anonymous");
			  image.onload = function() {
				var canvas = document.createElement("canvas");
				canvas.width = image.width;
				canvas.height = image.height;
				var context = canvas.getContext("2d");
				context.drawImage(image, 0, 0, image.width, image.height);
				var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
					
				var a = document.createElement("a"); // 生成一个a元素
				var event = new MouseEvent("click"); // 创建一个单击事件
				a.download = name || "photo"; // 设置图片名称
				a.href = url; // 将生成的URL设置为a.href属性
				a.dispatchEvent(event); // 触发a的单击事件
			  };
			  image.src = imgsrc;
			}
		}
	}
</script>

<style scoped lang="scss">
.modal-box{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.7);
	.modal-cancel{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
	.modal{
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		transform: translate(-50%,-50%);
		background-color: #fff;
		width: 600px;
		height: 400px;
		padding: 50px 30px;
		.imgs-box{
			display: flex;
			flex-wrap: wrap;
			width: 550px;
			.imgs{
				position: relative;
				margin-right: 20px;
				margin-bottom: 10px;
				.check{
					position: absolute;
					top: 5px;
					right: 2px;
					background-color: #fff;
					border-radius: 50%;
				}
			}
		}
		.btn{
			display: flex;
			justify-content: center;
			padding-top: 40px;
			.clo1{
				margin-right: 40px;
			}
		}
	}
}
</style>