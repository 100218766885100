<template>
	<!-- 添加需求记录 -->
	<div class="modal-box">
		<div class="third-box">
			<div class="first one" v-if="isTaskUpdate">
				<p>修改</p>
				<close-outlined @click="close()"/>
			</div>
			<div class="first one" v-else>
				<p v-if="updateId">修改需求记录</p>
				<p v-else>添加需求记录</p>
				<close-outlined @click="close()"/>
			</div>
			<div class="first1" v-if="!isTaskUpdate&&!updateId">
				<p>优先级</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleLevelClick">
							<a-menu-item v-for="(itm,ind) in levelArr" :key="ind">{{itm}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{levelArr[levelIndex]}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<template v-if="isTaskUpdate">
				<div class="first1">
					<p>需求补充</p>
					<a-textarea placeholder="需求补充" style="height: 120px;" v-model:value="value"/>
				</div>
				<div class="first1">
					<p>图片补充</p>
				    <a-upload
						name="file"
						v-model:file-list="fileList"
						:maxCount='8'
						list-type="picture-card"
						:customRequest ="customRequest"
						@remove="remove">
						<div class="addimg" v-if="fileList.length < 8">
							<plus-outlined />
							<p style="color: #999999;">点击上传凭证</p>
						</div>
				    </a-upload>
				</div>
			</template>
			<div class="first1" v-else-if="!updateId">
				<p>需求描述</p>
				<a-textarea placeholder="需求描述" style="height: 130px;" v-model:value="value"/>
			</div>
			<div class="first1" v-if="!isTaskUpdate&&updateId">
				<p>原需求描述</p>
				<a-textarea placeholder="原需求描述" style="height: 90px;" :value="describeOld" disabled/>
			</div>
			<div class="first1" v-if="!isTaskUpdate&&updateId">
				<p>修改为</p>
				<a-textarea placeholder="请输入需求描述" style="height: 90px;" v-model:value="value"/>
			</div>
			<div class="btn">
				<a-button class="clo1" @click="updateBtn()" v-if="updateId">提交</a-button>
				<a-button class="clo1" @click="submit()" v-else>提交</a-button>
				<a-button class="clo6" @click="close()">取消</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				value:"",
				levelArr:{} ,// 优先级列表
				levelIndex:'commonly',
				describeOld:null,
				fileList:[]
			}
		},
		computed:{
			updateId(){
				return this.$store.state.updateId
			},
			updateDescribe(){
				// 回显需求描述
				console.log('回显参数',this.$store.state.updateDescribe)
				this.value = this.$store.state.updateDescribe.value||this.$store.state.updateDescribe;
				this.fileList = this.$store.state.updateDescribe&&this.$store.state.updateDescribe.images&&this.$store.state.updateDescribe.images.map(it=>{
					return {
						status:'done',
						thumbUrl:it
					}
				});
				this.describeOld = this.$store.state.updateDescribe.value||this.$store.state.updateDescribe;
				return this.$store.state.updateDescribe
			},
			isTaskUpdate(){
				return this.$store.state.isTaskUpdate
			}
		},	
		watch:{
			updateDescribe(val,oldvalue){}
		},
		async created() {
			// 获取优先级
			let r = await this.$utils.api.get({
				url:'/demand_record/setting',
				result:1
			})
			console.log('updateDescribe ===>', this.$store.state.updateDescribe)
			if(r.code == 1){
				this.levelArr = r.data.priorityList
				this.levelIndex = 'commonly' // 默认显示一般
			}
		},
		methods:{
			close(){
				this.value = '';
				this.fileList = [];
				this.$store.commit('setIsRecordAdd',0) // 关闭
			},
			handleLevelClick(e){
				this.levelIndex = e.key
			},
			async customRequest (e) {
				// 执行图片上传
				let that = this
				let res = await this.$utils.api.upLoad(e)
				if(res){
					setTimeout(()=>{
						that.$utils.msgSuc('上传成功')
						// 上传成功修改文件状态
						that.fileList[that.fileList.length-1].status = 'done' 
						// 图片封面预览
						that.fileList[that.fileList.length-1].thumbUrl = res.data.fullurl 
						// 图片集合
						// that.formState.images.push(res.data.fullurl)
					},500)
				}
			},
			remove(e){
				// 删除图片
				this.fileList = this.fileList.filter((item) => {
				    return item.thumbUrl != e.thumbUrl
				})
			},
			async submit(){
				if(this.value == ""){
					this.$utils.msgWar('请输入需求描述')
					return
				}
				// 提交
				let res = await this.$utils.api.post({
					url:"/demand_record/add",
					data:{
						'row':{
							'describe':this.value,
							'priority':this.levelIndex
						}
					},
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('添加成功')
					this.$store.commit('setIsFresh',1) // 刷新列表
					this.close() // 关闭
				}
			},
			async updateBtn(){
				// 修改
				let url = ''
				if(this.isTaskUpdate){
					// 任务修改
					url= '/task/edit'
				}else{
					url= '/demand_record/edit'
				}
				var images = this.fileList.map(it=>it.thumbUrl);
				let res = await this.$utils.api.post({
					url:url,
					data:{
						ids:this.updateId,
						'row':{
							'describe':this.value,
							'images':images
						}
					},
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('修改成功')
					this.$store.commit('setUpdateDescribe',{value:this.value, images:images}) //回显
					this.$store.commit('setIsUpdateFresh',1) // 刷新列表
					this.close() // 关闭
				}
			}
		}	
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 500px;
			height: 460px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.first1{
				display: flex;
				padding: 30px 20px 0 20px;
				p{
					padding: 0;
					width: 100px;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
			.btn{
				display: flex;
				justify-content: center;
				margin-top: 40px;
				.clo1{
					margin-right: 30px;
				}
			}
		}
	}
</style>