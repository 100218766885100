import { createStore } from 'vuex';
import $utils from '@/components/utils/tools';

export default createStore({
  state: {
    user: null,// 用户信息
    configData: null,// 配置参数
    proId: 0,// 项目ID
    mainNavIndex: 0,// 主导航索引
    subNavIndex: 0,// 次导航索引
		selectedMemu:[],// 选中的菜单信息
		params: [],// 页面权限
		hideSider: 0,// 隐藏左侧
		history:null,// 右侧列表
		proName:'',// 项目名称？？？
		isSwiper:0 ,// 是否显示swiper
		isImgCheck:0 ,// 是否显示下载图片
		staticImgs:[] ,// 静态图片列表
		alignmentImgs:[] ,//联调图片列表
		menuList:[] ,// 模块菜单列表
		isImgOther:0 ,// 是否显示其他下载图片
		imgOtherArr:[] ,// 其他图片列表
		isThirdAdd:0 ,// 是否显示添加第三方
		isMemberAdd:0 ,// 是否显示添加团队成员
		isManagerAdd:0 ,// 是否显示创建项目
		isCustomerAdd:0 ,// 是否显示添加客户
		isRecordAdd:0 ,// 是否显示添加需求记录
		isSigning:0 ,// 是否显示签约
		signInfo:{} ,// 签约
		signId:null ,// 签约成功
		isFresh:0 ,//刷新列表
		updateId:null ,// 列表编辑id
		isUpdateFresh:0 ,// 编辑后刷新
		updateDescribe:"" ,// 需求描述
		isCollection:0 ,// 是否显示收款弹窗
		collectInfo:{} ,// 收款
		isAddCollect:0 ,// 是否显示追加弹窗
		isInvoice:0 ,// 是否显示发票弹窗
		isAddCollectFresh:0 ,// 是否刷新追加金额
		isCollectUpdate:0 ,//是否显示客户管理编辑弹窗
		isCollectUpdateFresh:0 ,// 是否刷新编辑后的客户管理信息
		swiperArr:[] ,// 图片列表
		isAddContract:0 ,// 是否显示合同收款弹窗
		// showBtn:true // 是否显示模块按钮
		isTaskUpdate:0 ,// 是否是任务修改
		updateStatus:null ,// 签约状态
		mainLable:"" ,// 导航一级菜单lable
		unresolvedForDev:0 ,// 开发指派我的bug未解决数量
		waitNotForDev:0 ,// 产品经理、项目经理、测试我提出的bug待确认数量
		overdueNum:0 // 任务逾期数量
  },
  mutations: {
      setStore (state, obj) {
          // obj.name state下的键名、obj.data 待设置的数据、obj.storage 数据是否缓存
          state[obj.name] = obj.data || null;
          if(obj.storage) {
              if(obj.data){
                  $utils.storeSet(obj.name, obj.data)
              } else {
                  $utils.storeClear(obj.name)
              }
          }
      },
	  setStoreName (state, res) {
	      state.proName = res
	  },

    // 多余设置开始
		  setSwiper(state,res){
			  state.isSwiper = res
		  },
		  setImgCheck(state,res){
			  state.isImgCheck = res
		  },
		  setStaticImgs(state,res){
			  state.staticImgs = res
		  },
		  setAlignmentImgs(state,res){
		  	  state.alignmentImgs = res
		  },
		  setImgOther(state,res){
			  state.isImgOther = res
		  },
		  setImgOtherArr(state,res){
			  state.imgOtherArr = res
		  },
		  setIsThirdAdd(state,res){
			  state.isThirdAdd = res
		  },
		  setIsMemberAdd(state,res){
			  state.isMemberAdd = res
		  },
		  setIsManagerAdd(state,res){
		  	  state.isManagerAdd = res
		  },
		  setIsCustomerAdd(state,res){
		  	  state.isCustomerAdd = res
		  },
		  setIsRecordAdd(state,res){
		  	  state.isRecordAdd = res
		  },
		  setIsSigning(state,res){
			   state.isSigning = res
		  },
		  setSignInfo(state,res){
			  state.signInfo = res
		  },
		  setSignId(state,res){
			  state.signId = res
		  },
		  setIsFresh(state,res){
		  	  state.isFresh = res
		  },
		  setIsUpdate(state,res){
		  	  state.isUpdate = res
		  },
		  setUpdateId(state,res){
		  	  state.updateId = res
		  },
		  setIsUpdateFresh(state,res){
			  state.isUpdateFresh = res
		  },
		  setUpdateDescribe(state,res){
			  state.updateDescribe = res
		  },
		  setIsCollection(state,res){
			  state.isCollection = res
		  },
		  setCollectInfo(state,res){
		  	  state.collectInfo = res
		  },
		  setIsAddCollect(state,res){
			  state.isAddCollect = res
		  },
		  setIsInvoice(state,res){
		  	  state.isInvoice = res
		  },
		  setIsAddCollectFresh(state,res){
			  state.isAddCollectFresh = res
		  },
		  setIsCollectUpdate(state,res){
			  state.isCollectUpdate = res
		  },
		  setIsCollectUpdateFresh(state,res){
			  state.isCollectUpdateFresh = res
		  },
		  setSwiperArr(state,res){
			  state.swiperArr = res
		  },
		  setIsAddContract(state,res){
			   state.isAddContract = res
		  },
		  setMenuList(state,res){
			  state.menuList = res
		  },
		  setShowBtn(state,res){
			  state.showBtn = res
		  },
		  setMenuUrl(state,res){
			  state.menuUrl = res
		  },
		  setIsTaskUpdate(state,res){
			   state.isTaskUpdate = res
		  },
		  setUpdateStatus(state,res){
			   state.updateStatus = res
		  },
    // 多余设置结束
  },
  actions: {
  },
  modules: {
  },
});
