<template>
	<!-- 添加客户 -->
	<div class="modal-box">
		<a-form 
			class="third-box" 
			:model="info"
			:label-col="{ span: 8}"
			:wrapper-col="{ span: 12 }"
			:rules="rulesForm"
			@finish="onFinish">
			<div class="first one">
				<p>{{updateId ? "编辑" : "添加客户"}}</p>
				<close-outlined @click="close()"/>
			</div>
			<a-form-item
				label="项目名称" 
				name="name" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入项目名称' }]">
			    <a-input v-model:value="info.name" placeholder="请输入项目名称" :disabled="updateId&&updateStatus!='no_signed'?true:false"/>
			</a-form-item>
			<a-form-item
				label="客户名称" 
				name="nickname" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入客户名称' }]">
			    <a-input v-model:value="info.nickname" placeholder="请输入客户名称" />
			</a-form-item>
			<a-form-item
				label="客户标签" 
				name="label_ids" 
				:colon= "false" 
				:rules="[{ required: true, message: '请选择客户标签' }]">
			    <a-select
			       mode="tags"
			       style="width: 100%"
				   searchValue= ""
			       placeholder="请选择标签"
				   :value="lableArr"
			       :options="stateArr"
			       @change="handleChange"
			     ></a-select>
			</a-form-item>
			<a-form-item
				label="联系人" 
				name="linkman" 
				:colon= "false" 
				:rules="[{ required: true, message: '请输入联系人' }]">
			    <a-input v-model:value="info.linkman" placeholder="请输入联系人" />
			</a-form-item>
			<a-form-item
				label="联系方式" 
				name="contact" 
				:colon= "false">
			    <a-input v-model:value="info.contact" placeholder="请输入联系方式" />
			</a-form-item>
			<a-form-item
				label="备注" 
				name="remark" 
				:colon= "false" 
				:rules="[{ required: false, message: '请输入备注' }]">
			    <a-input v-model:value="info.remark" placeholder="请输入备注" />
			</a-form-item>
			<a-form-item
				label="重要程度" 
				name="level" 
				:colon= "false" 
				:rules="[{ required: true, message: '请选择重要程度' }]">
			    <a-dropdown>
			        <template #overlay>
			            <a-menu selectable @click="handleLevelClick">
			    			<a-menu-item v-for="(item,index) in levelArr" :key="index">{{item}}</a-menu-item>
			            </a-menu>
			        </template>
			        <a-button>
			    		{{levelIndex != 1 ? levelArr[levelIndex] : '一级'}}
			            <DownOutlined />
			        </a-button>
			    </a-dropdown>
			</a-form-item>
			<a-form-item  :wrapper-col="{ offset:8}" style="margin-top: 50px;">
			  <a-button class="clo1" style="margin-right: 50px;" @click="updateBtn()" v-if="updateId">提交</a-button>
			  <a-button class="clo1" style="margin-right: 50px;" html-type="submit" v-else>提交</a-button>
			  <a-button class="clo6" @click="close()">取消</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
	export default{
		data(){
			// 联系方式效验
			let phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/
			let validatePhone = async (rule, value) => {
				if (!value) {
					// return callback(new Error('请输入联系方式'))
					return Promise.reject('请输入联系方式');
				}
				// if (!phoneReg.test(value)) {
				// 	// callback(new Error('联系方式格式有误'))
				// 	return Promise.reject('联系方式格式有误');
				// } else {
				// 	// callback()
				// 	return Promise.resolve();
				// }
			}
			return{
				stateArr:[], // 标签
				stateIndex:0, // 标签下标
				levelArr:{}, // 重要程度
				levelIndex:1, // 重要程度下标
				info:{
					name:'', // 项目名称
					nickname:'' ,//客户名称
					label_ids:[], // 客户标签
					linkman:'' , // 联系人
					contact:'' , // 联系方式 
					remark:'' , // 备注
					level:1// 重要程度
				},
				rulesForm: { // 自定义效验规则
					contact: [{
						required: true, 
						validator: validatePhone,
						trigger: 'change'
					}],
				},
				lableArr:[] // 标签名称
			}
		},
		computed:{
			updateId(){
				// 根据编辑id 请求列表详情
				let that = this
				if(this.$store.state.updateId){
					that.getInfo(that.$store.state.updateId)
				}
				return this.$store.state.updateId
			},
			updateStatus(){
				return this.$store.state.updateStatus
			}
		},
		async created() {
			// 获取客户标签
			let res = await this.$utils.api.get({
				url:'/sale/label_selectpage'
			})
			for(let i in res.list){
				res.list[i].value = res.list[i].name
			}
			this.stateArr = res.list

			// 获取重要程度
			let res1 = await this.$utils.api.get({
				url:'/sale/setting'
			})
			this.levelArr = res1.levelList
		},
		methods:{
			close(){
				// 关闭弹窗
				this.$store.commit('setIsCustomerAdd',0)
			},
			handleChange(value,item){
				// 多选标签
				let ids = []
				let arr = []
				for(let i in item){
					ids.push(item[i].id)
					arr.push(item[i].name)
				}
				this.info.label_ids = ids
				this.lableArr = arr
			},
			handleLevelClick(e){
				// 选择重要程度
				this.levelIndex = e.key
				this.info.level = this.levelArr[e.key]
			},
			async onFinish(e){
				// 提交-验证完成后
				if(e.name == ''){
					this.$message.error('项目名称不能为空');
					return
				}
				if(e.nickname == ''){
					this.$message.error('客户名称不能为空');
					return
				}
				if(e.label_ids.length == 0){
					this.$message.error('客户标签不能为空');
					return
				}
				if(e.linkman == ''){
					this.$message.error('联系人不能为空');
					return
				}
				if(e.contact == ''){
					this.$message.error('联系方式不能为空');
					return
				}
				
				let row = {
					'row':{
						'name':e.name,
						'level':this.levelIndex,
						'nickname':e.nickname,
						'linkman':e.linkman,
						'contact':e.contact,
						'remark':e.remark,
						'label_ids':e.label_ids
					}
				}
				let res = await this.$utils.api.post({
					url:'/sale/add',
					data:row,
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('添加成功')
					this.$store.commit('setIsFresh',1) // 刷新列表
					this.close() // 关闭
				}
			},
			async getInfo(id){
				// 编辑详情
				let res = await this.$utils.api.post({
					url:'/sale/row',
					data:{
						ids:id
					},
					result:1
				})
				if(res.code == 1){
					let arr = []
					for(let i in res.data.labels_arr){
						arr.push(res.data.labels_arr[i].id)
						this.lableArr.push(res.data.labels_arr[i].name)
					}
					this.info = {
						name:res.data.name, // 项目名称
						nickname:res.data.nickname,//客户名称
						label_ids:arr, // 客户标签
						linkman:res.data.linkman, // 联系人
						contact:res.data.contact, // 联系方式 
						remark:res.data.remark, // 备注
						level:res.data.level// 重要程度
					}
					this.levelIndex = res.data.level
				}
			},
			async updateBtn(){
				if(this.info.name == ''){
					this.$message.error('项目名称不能为空');
					return
				}
				if(this.info.nickname == ''){
					this.$message.error('客户名称不能为空');
					return
				}
				if(this.info.label_ids.length == 0){
					this.$message.error('客户标签不能为空');
					return
				}
				if(this.info.linkman == ''){
					this.$message.error('联系人不能为空');
					return
				}
				if(this.info.contact == ''){
					this.$message.error('联系方式不能为空');
					return
				}
				// 修改
				let row = {
					'ids':this.updateId,
					'row':{
						'name':this.info.name,
						'level':this.levelIndex,
						'nickname':this.info.nickname,
						'linkman':this.info.linkman,
						'contact':this.info.contact,
						'remark':this.info.remark,
						'label_ids':this.info.label_ids
					}
				}
				let res = await this.$utils.api.post({
					url:'/sale/edit',
					data:row,
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('修改成功')
					this.$store.commit('setIsUpdateFresh',1) // 刷新列表
					this.close() // 关闭
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.modal-box{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.7);
		.third-box{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
			width: 550px;
			height:620px;
			background-color: #fff;
			border-radius: 8px;
			.first{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;
				margin-bottom: 50px;
				p{
					margin: 0;
					padding: 0;
				}
			}
			.one{
				border-bottom: 1px solid #ddd;
			}
		}
	}
</style>