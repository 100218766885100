<template>
  <div>
    <!-- <h1 class="loginLogo"><span>黑盒大数据</span></h1> -->
    <LoginModule class="login"></LoginModule>
  	<div class="bg">
  		<img src="../static/text.png" alt="">
  	</div>
    <!-- <Background></Background> -->
    <!-- <iframe class="bg" src="../static/background.html" frameborder="0"></iframe> -->
  </div>
</template>

<script>
// @ is an alias to /src
import LoginModule from '@/components/LoginModule.vue';
import Background from '@/components/Background.vue';

export default {
  name: 'Login',
  components: {
    LoginModule,
    Background,
  },
  data(){
    return {
		
    }
  },
 
  
};
</script>

<style lang="scss">
.login{
  position: fixed;
  top:50%;
  left:200px;
  z-index: 2;
  transform: translateY(-50%);
}
.loginLogo{
  position: fixed;
  left:200px;
  bottom:50%;
  margin-bottom:60px;
  width:360px;
  height:187px;
  z-index: 2;
  background:url(../static/login-logo.png) no-repeat center center;
  background-size:100% 100%;
  span{
    display: none;
  }
}
.bg{
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	width: 621px;
	background:url(../static/logobg.png) no-repeat;
	background-size: cover;
	img{
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;
	}
}
</style>

